import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledLabel = styled.label`
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 0 0 1rem;
  font-weight: bold;
`;

export const StyledSubLabel = styled.p`
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0 1rem;
  opacity: 0.8;
`;
