import React, { useContext, useMemo } from 'react';
import {
  UpdateVerifiedNativeUsersMutation,
  CreateVerifiedNativeUsersInput,
  CreateVerifiedNativeUsersMutation,
  UpdateVerifiedNativeUsersInput,
  DeleteVerifiedNativeUsersInput,
  DeleteVerifiedNativeUsersMutation,
} from 'API';
import { QueryResult, MutationResult, MutateFunction } from 'react-query';
import filterActiveUsers from './utils/filterActiveUsers';
import useUsers from './utils/useUsers/useUsers';

type UsersContext = {
  list: QueryResult<CreateVerifiedNativeUsersInput[]>;

  setActiveStatus: [
    MutateFunction<UpdateVerifiedNativeUsersMutation, { formData: UpdateVerifiedNativeUsersInput }>,
    MutationResult<UpdateVerifiedNativeUsersMutation>,
  ];

  deleteUser: [
    MutateFunction<DeleteVerifiedNativeUsersMutation, { input: DeleteVerifiedNativeUsersInput }>,
    MutationResult<DeleteVerifiedNativeUsersMutation>,
  ];

  createUser: [
    MutateFunction<
      CreateVerifiedNativeUsersMutation,
      {
        user: string;
      }
    >,
    MutationResult<CreateVerifiedNativeUsersMutation>,
  ];
};

// context that provides common interactions with users of the organization
export const UsersContext = React.createContext<UsersContext | undefined>(undefined);

type UsersProviderProps = {
  children: React.ReactElement | React.ReactElement[];
};

// provides the context via wrapper
export const UsersProvider = ({ children }: UsersProviderProps) => {
  const users = useUsers();

  return <UsersContext.Provider value={users}>{children}</UsersContext.Provider>;
};

// hooks that returns the user list
export const useUsersList = () => {
  const context = useContext(UsersContext);

  if (context === undefined) {
    throw new Error('useUsersList must be used within a UsersProvider');
  }

  return context.list;
};

// hooks the returns the active user list
export const useActiveUsersList = () => {
  const context = useContext(UsersContext);
  const filteredUsers = useMemo(() => {
    return filterActiveUsers(context?.list?.data);
  }, [context]);

  if (context === undefined) {
    throw new Error('useActiveUsersList must be used within a UsersProvider');
  }

  return { ...context.list, data: filteredUsers };
};

// hooks that returns the createUser function
export const useCreateUser = () => {
  const context = useContext(UsersContext);

  if (context === undefined) {
    throw new Error('useUsersList must be used within a UsersProvider');
  }

  return context.createUser;
};

// hooks that returns the deleteUser function
export const useDeleteUser = () => {
  const context = useContext(UsersContext);

  if (context === undefined) {
    throw new Error('useUsersList must be used within a UsersProvider');
  }

  return context.deleteUser;
};
