import React, { useContext, useState } from 'react';

type OrganizationContext = [string, Function];

// context that controls the current organization
export const OrganizationContext = React.createContext<OrganizationContext | undefined>(undefined);

// provides the context via wrapper
export const OrganizationProvider = ({ children }: { children: React.ReactNode }) => {
  const organizationState = useState('');

  return (
    <OrganizationContext.Provider value={organizationState}>
      {children}
    </OrganizationContext.Provider>
  );
};

// hook that returns the context
export const useOrganization = () => {
  const context = useContext(OrganizationContext);

  if (context === undefined) {
    throw new Error('useOrganization must be used within a OrganizationProvider');
  }

  return context;
};
