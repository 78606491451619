import styled from 'styled-components';

export const TextInput = styled.input`
  all: unset;
  background-color: white;
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  box-sizing: border-box;
  padding: 8px 16px;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 8px;
  font-family: ${(props) => props.theme.bodyFont};
`;

export const RangePadding = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
`;
