import React from 'react';
import { Header, DateRangePicker } from 'elements';
import { ActivityMap } from 'components/user';
import { MapHeader } from './Activity.styled';

const Activity = () => {
  return (
    <>
      <MapHeader>
        <Header>Activity Map</Header>
        <DateRangePicker />
      </MapHeader>
      <ActivityMap />
    </>
  );
};

export default Activity;
