import React from 'react';
import { Center } from 'styles/utils';
import './LoadingIcon.css';

export const InlineLoadingIcon = () => {
  return (
    <div
      className="sk-folding-cube"
      style={{ width: '20px', height: '20px', margin: ' 0 18px 0 0' }}
    >
      <div className="sk-cube1 sk-cube" />
      <div className="sk-cube2 sk-cube" />
      <div className="sk-cube4 sk-cube" />
      <div className="sk-cube3 sk-cube" />
    </div>
  );
};

type LoadingIconProps = {
  height?: string;
};

const LoadingIcon = ({ height = '600px' }: LoadingIconProps) => {
  return (
    <div className="loading-spinner-container" data-testid="loading" style={{ height }}>
      <Center>
        <div className="sk-folding-cube">
          <div className="sk-cube1 sk-cube" />
          <div className="sk-cube2 sk-cube" />
          <div className="sk-cube4 sk-cube" />
          <div className="sk-cube3 sk-cube" />
        </div>
      </Center>
    </div>
  );
};

export default LoadingIcon;
