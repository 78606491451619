import styled from 'styled-components';
import { Input, Body } from 'elements';

export const Container = styled.div`
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  background-color: white;
  padding: 32px 32px 28px 32px;
  border-radius: ${(props) => props.theme.borderRadius};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 64px;
  @media (max-width: 1730px) {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }
`;

export const Setting = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 8px;
  align-items: center;
`;

export const SettingsInput = styled(Input)`
  margin: 0;
`;

export const ActionRow = styled.div`
  margin-top: 16px;
`;

export const StyledLabel = styled.label`
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
  font-weight: bold;
`;
export const StyledSubLabel = styled(Body)`
  margin: 0;
`;
