import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useUsersList, useCreateUser } from 'context/users';
import { useAlert } from 'context/alert';
import { Button, Body, Modal, ModalActions, ModalContent, ModalHeader } from 'elements';
import TextField from '@material-ui/core/TextField';
import { AddUserContainer } from './AddUser.styled';

const AddUser = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { refetch } = useUsersList();
  const [createUser, createUserMutation] = useCreateUser();
  const { handleSubmit, formState, control } = useForm();
  const { errorAlert, successAlert } = useAlert();
  const { isSubmitting } = formState;

  useEffect(() => {
    if (!isSubmitting && !createUserMutation.isIdle) {
      if (createUserMutation.isError) {
        errorAlert({ message: 'Failed to create user' });
        createUserMutation.reset();
      }

      if (createUserMutation.isSuccess) {
        successAlert({ message: 'Successfully created new user' });
        createUserMutation.reset();
        setIsDialogOpen(false);
      }
    }
  }, [isSubmitting, createUserMutation.status, createUserMutation, errorAlert, successAlert]);

  return (
    <>
      <AddUserContainer>
        <Button onClick={() => setIsDialogOpen(true)}>Add User</Button>
      </AddUserContainer>

      <Modal open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <form
          onSubmit={handleSubmit(async ({ userId }) => {
            await createUser({ user: userId });
            await refetch({});
          })}
        >
          <ModalContent>
            <ModalHeader>Add User</ModalHeader>
            <Body secondary noMargins>
              User will be added to the organization. Anyone with the app installed will be able to
              register with this user ID.
            </Body>
            <Controller
              as={TextField}
              required
              control={control}
              id="userId"
              name="userId"
              label="User ID"
              defaultValue=""
              type="text"
              margin="normal"
              fullWidth
              autoComplete="off"
              InputLabelProps={{ required: false }}
            />
          </ModalContent>
          <ModalActions>
            <Button loading={isSubmitting} type="text" onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
            <Button loading={isSubmitting} type="text" htmlType="submit">
              Create
            </Button>
          </ModalActions>
        </form>
      </Modal>
    </>
  );
};

export default AddUser;
