import React from 'react';
import { Navigation, Fullscreen } from './MapControls.styled';

const MapControls = () => {
  return (
    <>
      <Navigation />
      <Fullscreen />
    </>
  );
};

export default MapControls;
