import styled from 'styled-components';
import { Card, Header, SubHeader, Body } from 'elements';

export const Container = styled(Card)`
  margin-bottom: 2rem;
  padding: 16px 32px;
`;

export const CardTitle = styled(Header)`
  margin-bottom: 0;
`;

export const Timestamp = styled(SubHeader)`
  margin-top: 0;
`;

export const Data = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 950px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DataLabel = styled(Body)`
  margin: 0;
`;

export const DataValue = styled(SubHeader)`
  margin-top: 0.5rem;
`;
