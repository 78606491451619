import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import { Button, Header, Body } from 'elements';

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 8px 0;
    background-color: white;
    border-radius: ${(props) => props.theme.borderRadius};
    align-items: center;
  }
`;

export const UserId = styled(Header)`
  margin: 0;
`;

export const Timestamp = styled(Body)`
  margin: 0;
`;

export const Data = styled.div`
  position: relative;
  display: grid;
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  grid-template-columns: 1fr auto;
  align-items: center;
  background-color: white;
  padding: 22px 32px;
  border-radius: ${(props) => props.theme.borderRadius};
  overflow: hidden;
`;

export const DataLabel = styled(Body)`
  margin: 0;
`;

export const StartColumn = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
`;

export const EndColumn = styled.div`
  display: flex;
`;

export const DeleteButton = styled(Button)`
  margin: 0 !important;
`;

export const Disabled = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const OptOutHeader = styled(Header)`
  color: white;
  margin: 0 1rem 0 0;
`;
