import React, { useState, useEffect } from 'react';
import { useDeleteUser } from 'context/users';
import { Button, Body, Modal, ModalActions, ModalContent, ModalHeader } from 'elements';
import { CreateVerifiedNativeUsersInput } from 'API';
import { DeleteButton } from './DeleteUser.styled';

type DeleteUserProps = {
  user: CreateVerifiedNativeUsersInput;
  handleDeleteUser: Function;
};

const DeleteUser = ({ user, handleDeleteUser }: DeleteUserProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteUser, deleteUserMutation] = useDeleteUser();

  useEffect(() => {
    if (!isSubmitting && !deleteUserMutation.isIdle) {
      if (deleteUserMutation.isError) {
        handleDeleteUser(deleteUserMutation);
      }

      if (deleteUserMutation.isSuccess) {
        handleDeleteUser(deleteUserMutation);
        setIsDialogOpen(false);
      }
    }
  }, [deleteUserMutation, handleDeleteUser, isSubmitting]);

  return (
    <>
      <DeleteButton
        type="text"
        onClick={() => setIsDialogOpen(true)}
        data-testid={`${user.user}-delete`}
      >
        Delete
      </DeleteButton>

      <Modal open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <ModalContent>
          <ModalHeader>Delete User</ModalHeader>
          <Body secondary>User will permanently be deleted from this organization.</Body>
        </ModalContent>
        <ModalActions>
          <Button loading={isSubmitting} type="text" onClick={() => setIsDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            loading={isSubmitting}
            type="text"
            onClick={() => {
              setIsSubmitting(true);
              deleteUser({
                input: {
                  user: user.user,
                  organization: user.organization,
                },
              });
            }}
          >
            Delete
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default DeleteUser;
