import React from 'react';
import { LoadingIcon, ErrorHandler } from 'elements';
import { useHistory } from 'react-router-dom';
import { useUserProfile } from 'components/user/__hooks__';
import { useDateRange } from 'context/date-range';
import { Container } from './UserExportButtons.styled';
import { useUserCsv, useDailyQuestions } from '../__hooks__';

const ExportButtons = () => {
  const history = useHistory();
  const [dateRange] = useDateRange();
  const {
    user,
    userData: {
      isLoading: isLocationLoading,
      isError: isLocationError,
      data: locationData,
      error: locationError,
    },
  } = useUserProfile({ dateRange });

  const {
    list: {
      isLoading: isDailyQuestionsLoading,
      isError: isDailyQuestionsError,
      data: dailyQuestionsData,
      error: dailyQuestionsError,
    },
  } = useDailyQuestions();

  const { ExportLocationData, ExportDailyQuestionsData, ExportActivity } = useUserCsv({
    locationData,
    dailyQuestionsData,
    dateRange,
  });

  if (isLocationLoading || isDailyQuestionsLoading) {
    return <LoadingIcon />;
  }

  if (isLocationError) {
    return (
      <ErrorHandler
        error={locationError || dailyQuestionsError}
        message="Failed to fetch user's location data"
      />
    );
  }

  if (isDailyQuestionsError) {
    return (
      <ErrorHandler
        error={locationError || dailyQuestionsError}
        message="Failed to fetch user's daily questions data"
      />
    );
  }

  if (!user) {
    history.push('/');
    return null;
  }

  return (
    <Container>
      {ExportLocationData}
      {ExportActivity}
      {ExportDailyQuestionsData}
    </Container>
  );
};

export default ExportButtons;
