import React from 'react';
import { LocationHeatMap, DeviceInformation } from 'components/user';
import { useUserProfile } from 'components/user/__hooks__';
import moment from 'moment';

const startDate = moment().subtract(30, 'days');
const endDate = moment();

const Overview = () => {
  const { user, userData } = useUserProfile({
    dateRange: {
      start: startDate,
      end: endDate,
    },
  });

  return (
    <>
      <LocationHeatMap user={user} userData={userData} />
      <DeviceInformation user={user} />
    </>
  );
};

export default Overview;
