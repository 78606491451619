import styled from 'styled-components';
import { Button } from 'elements';

export const Container = styled.div`
  background-color: white;
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  padding: 16px 32px 28px 32px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0px 32px;

  @media (max-width: 1730px) {
    grid-template-columns: 1fr;
  }
`;

export const Control = styled.div`
  display: flex;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const SliderContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const SubmitButton = styled(Button)`
  margin-right: 1rem;
  @media (max-width: 900px) {
    margin-right: 0rem;
    margin-bottom: 0.5rem;
  }
`;
