import React, { useContext } from 'react';
import useAlertUtils from './utils/useAlertUtils/useAlertUtils';

type AlertContext = {
  Alert: JSX.Element;
  successAlert: ({ message }: { message: string }) => void;
  errorAlert: ({ message }: { message: string }) => void;
};

// context that controls the alert component
export const AlertContext = React.createContext<AlertContext | undefined>(undefined);

// provides the context via wrapper
export const AlertProvider = ({ children }: { children: React.ReactNode }) => {
  const alertState = useAlertUtils();

  return <AlertContext.Provider value={alertState}>{children}</AlertContext.Provider>;
};

// hook that returns the context
export const useAlert = () => {
  const context = useContext(AlertContext);

  if (context === undefined) {
    throw new Error('useAlert must be used within a AlertProvider');
  }

  return context;
};
