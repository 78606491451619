import React from 'react';
import MaterialSlider from '@material-ui/core/Slider';
import { Container, Labels, LeftLabels, RightLabels, MiddleLabels, Label } from './Slider.styled';

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
  {
    value: 7,
    label: '7',
  },
  {
    value: 8,
    label: '8',
  },
  {
    value: 9,
    label: '9',
  },
  {
    value: 10,
    label: '10',
  },
];

type SliderProps = {
  id: string;
  name: string;
  defaultValue?: number;
  step?: number;
  min?: number;
  max?: number;
  leftLabels?: string[];
  middleLabels?: string[];
  rightLabels?: string[];
  onChange: ((event: React.ChangeEvent<{}>, value: number | number[]) => void) | undefined;
};

const Slider = ({
  id,
  name,
  defaultValue = 6,
  step = 1,
  min = 1,
  max = 10,
  leftLabels,
  rightLabels,
  middleLabels,
  onChange,
}: SliderProps) => {
  return (
    <Container>
      <Labels>
        <LeftLabels>
          {leftLabels &&
            leftLabels.map((label) => (
              <Label secondary key={label}>
                {label}
              </Label>
            ))}
        </LeftLabels>
        <MiddleLabels>
          {middleLabels &&
            middleLabels.map((label) => (
              <Label secondary key={label}>
                {label}
              </Label>
            ))}
        </MiddleLabels>
        <RightLabels>
          {rightLabels &&
            rightLabels.map((label) => (
              <Label secondary key={label}>
                {label}
              </Label>
            ))}
        </RightLabels>
      </Labels>
      <MaterialSlider
        id={id}
        name={name}
        aria-labelledby={name}
        defaultValue={defaultValue}
        step={step}
        min={min}
        max={max}
        marks={marks}
        valueLabelDisplay="auto"
        onChange={onChange}
      />
    </Container>
  );
};

export default Slider;
