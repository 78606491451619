import { useQuery } from 'react-query';
import { CreateRadarEventsInput } from 'API';
import { useOrganization } from 'context/organization';
import fetchOrganizationLocationData from './utils/fetchOrganizationLocationData';

type useOrganizationLocationDataProps = {
  dateRange: {
    start: moment.Moment;
    end: moment.Moment;
  };
};

const useOrganizationLocationData = ({ dateRange }: useOrganizationLocationDataProps) => {
  const [organization] = useOrganization();
  const query = useQuery<
    {
      locationData: CreateRadarEventsInput[];
      activityData: CreateRadarEventsInput[];
      organization: string;
    },
    [
      string,
      {
        dateRange: {
          start: moment.Moment;
          end: moment.Moment;
        };
        organization: string;
      },
    ]
  >(['organizationLocationData', { dateRange, organization }], fetchOrganizationLocationData);

  return query;
};

export default useOrganizationLocationData;
