import styled from 'styled-components';
import { Body } from '../Typography/Typography';

export const Container = styled.div<{ marginTop: string }>`
  height: 2rem;
  margin-top: ${(props) => props.marginTop};
`;

export const RequiredLabel = styled(Body)`
  margin: 0 0 0 1rem;
  color: ${(props) => props.theme.danger1};
`;
