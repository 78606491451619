import React from 'react';
import { Body, Card, ErrorHandler, LoadingIcon } from 'elements';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useSearchbox } from 'components/user/__hooks__';
import { Center } from 'styles/utils';
import { CreateVerifiedNativeUsersInput } from 'API';
import { useActiveUsersList } from 'context/users';
import { UserEntryContainer, Link } from './UserList.styled';

const UserEntry = ({ user: { user } }: { user: CreateVerifiedNativeUsersInput }) => {
  return (
    <Link data-testid="search-result" to={`/user/${user}`}>
      <UserEntryContainer>
        <Body noMargins>{user}</Body>
        <AiOutlineArrowRight fontSize="1.1em" />
      </UserEntryContainer>
    </Link>
  );
};

const UsersList = () => {
  const { isLoading, isError, data, error } = useActiveUsersList();
  const [Searchbox, filteredUsers] = useSearchbox(data);

  if (isLoading) {
    return <LoadingIcon />;
  }

  if (isError || data === undefined || data === null) {
    return <ErrorHandler error={error} message="Failed to fetch active users list" />;
  }

  return (
    <>
      {Searchbox}
      {filteredUsers && filteredUsers.length !== 0 ? (
        <Card noPadding>
          {filteredUsers.map((user) => (
            <UserEntry key={`${user.user}-${user.organization}`} user={user} />
          ))}
        </Card>
      ) : (
        <Card>
          <Center>
            <Body>No users found</Body>
          </Center>
        </Card>
      )}
    </>
  );
};

export default UsersList;
