import React, { useState, useEffect } from 'react';
import { ErrorHandler, SubHeader, LoadingIcon } from 'elements';
import MapGL, { Source, Layer } from 'react-map-gl';
import { useHistory } from 'react-router-dom';
import { CreateVerifiedNativeUsersInput, CreateRadarEventsInput } from 'API';
import { QueryResult } from 'react-query';
import { useHeatData } from '../__hooks__';
import { Container, FixedContainer } from './LocationHeatMap.styled';

type LocationHeatMapProps = {
  user: CreateVerifiedNativeUsersInput | undefined;
  userData: QueryResult<CreateRadarEventsInput[]>;
};

const LocationHeatMap = ({
  user,
  userData: { isLoading, isError, data, error },
}: LocationHeatMapProps) => {
  const history = useHistory();
  const { heatData, startingViewport } = useHeatData(data);

  const [viewport, setViewport] = useState({
    width: 40,
    height: -100,
    latitude: 0,
    longitude: 0,
    zoom: 7,
  });

  // set viewport to first point when map is loaded
  useEffect(() => {
    setViewport(startingViewport);
  }, [startingViewport, setViewport]);

  if (isLoading) {
    return <LoadingIcon height="300px" />;
  }

  if (isError) {
    return <ErrorHandler error={error} message="Failed to fetch user's location data" />;
  }

  if (!user) {
    history.push('/');
    return null;
  }

  if (data === undefined || data.length === 0) {
    return (
      <FixedContainer>
        <SubHeader>No location data exists for this user in the past 30 days.</SubHeader>
      </FixedContainer>
    );
  }

  const MAX_ZOOM_LEVEL = 17;

  return (
    <Container>
      <MapGL
        mapStyle="mapbox://styles/mapbox/dark-v9"
        width="100%"
        height={300}
        latitude={viewport.latitude}
        longitude={viewport.longitude}
        zoom={viewport.zoom}
        maxZoom={MAX_ZOOM_LEVEL}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      >
        <Source type="geojson" data={heatData}>
          <Layer
            maxzoom={24}
            type="heatmap"
            paint={{
              // Increase the heatmap weight based on frequency and property magnitude
              'heatmap-weight': ['interpolate', ['linear'], ['get', 'mag'], 0, 0, 6, 1],
              // Increase the heatmap color weight weight by zoom level
              // heatmap-intensity is a multiplier on top of heatmap-weight
              'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
              // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
              // Begin color ramp at 0-stop with a 0-transparancy color
              // to create a blur-like effect.
              'heatmap-color': [
                'interpolate',
                ['linear'],
                ['heatmap-density'],
                0,
                'rgba(33,102,172,0)',
                0.2,
                'rgb(103,169,207)',
                0.4,
                'rgb(209,229,240)',
                0.6,
                'rgb(253,219,199)',
                0.8,
                'rgb(239,138,98)',
                0.9,
                'rgb(255,201,101)',
              ],
              // Adjust the heatmap radius by zoom level
              'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, MAX_ZOOM_LEVEL, 20],
              // Transition from heatmap to circle layer by zoom level
              'heatmap-opacity': ['interpolate', ['linear'], ['zoom'], 7, 1, MAX_ZOOM_LEVEL, 0],
            }}
          />
        </Source>
      </MapGL>
    </Container>
  );
};

export default LocationHeatMap;
