import React, { useState, useContext } from 'react';
import moment from 'moment';

type DateRangeContext = [
  {
    start: moment.Moment;
    end: moment.Moment;
  },
  Function,
];

// context that controls the date range component
export const DateRangeContext = React.createContext<DateRangeContext | undefined>(undefined);

// provides the context via wrapper
export const DateRangeProvider = ({ children }: { children: React.ReactNode }) => {
  const dateRangeState = useState({ start: moment(), end: moment() });

  return <DateRangeContext.Provider value={dateRangeState}>{children}</DateRangeContext.Provider>;
};

// hook that returns the context
export const useDateRange = () => {
  const context = useContext(DateRangeContext);

  if (context === undefined) {
    throw new Error('useDateRange must be used within a DateRangeProvider');
  }

  return context;
};
