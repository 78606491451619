import styled from 'styled-components';
import { Header, SubHeader } from 'elements';

export const DeviceInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 2fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  flex-grow: 1;
  background-color: white;
  padding: 16px;
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  transition: color 0.1s, background-color 0.1s;

  &:hover {
    background-color: ${(props) => props.theme.primary2};
    color: white;
  }
`;

export const Label = styled(SubHeader)`
  font-size: 0.9rem;
  white-space: nowrap;
  margin: 0;
`;

export const Value = styled(Header)`
  font-size: 1.7rem;

  display: flex;
  align-items: center;
  margin: 0.5rem 0 0 0;
`;
