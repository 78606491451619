import { API, graphqlOperation } from 'aws-amplify';
import { queryVerifiedNativeUsersByOrganizationActiveIndex } from 'graphql-custom/queries';
import {
  QueryVerifiedNativeUsersByOrganizationActiveIndexQuery,
  CreateVerifiedNativeUsersInput,
} from 'API';
import _ from 'lodash';

type fetchUserList = (
  key: string,
  organization: string,
) => Promise<CreateVerifiedNativeUsersInput[]>;

const fetchUserList: fetchUserList = async (key, organization) => {
  // fetch users by group
  const { data } = (await API.graphql(
    graphqlOperation(queryVerifiedNativeUsersByOrganizationActiveIndex, {
      organization,
      first: 1000,
    }),
  )) as {
    data: QueryVerifiedNativeUsersByOrganizationActiveIndexQuery;
  };

  // return empty array if invalid return
  if (!data.queryVerifiedNativeUsersByOrganizationActiveIndex?.items) return [];

  // original array
  const verifiedNativeUsersArray: (CreateVerifiedNativeUsersInput | null)[] =
    data.queryVerifiedNativeUsersByOrganizationActiveIndex.items;

  // filter out null values
  const filteredArray: CreateVerifiedNativeUsersInput[] = verifiedNativeUsersArray.filter(
    (item: CreateVerifiedNativeUsersInput | null): item is CreateVerifiedNativeUsersInput =>
      item !== null,
  );

  // put all the inactive users at the bottom and sort by active
  const sortedArray = _.orderBy(
    filteredArray,
    [
      'active',
      // sort alphabetically
      (item) => item.user.replace(/[0-9]/g, ''),
      // then sort by number suffix
      (item) => +item.user.replace(/^\D+/g, ''),
    ],
    ['desc', 'asc', 'asc'],
  );

  return sortedArray;
};

export default fetchUserList;
