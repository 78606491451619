import styled from 'styled-components';
import { Popup } from 'react-map-gl';
import { SubHeader, Body } from 'elements';
import Chip from '@material-ui/core/Chip';

export const TopRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
`;

export const Title = styled(SubHeader)`
  margin: 0 0.5rem 0 0;
`;

export const Timestamp = styled(Body)`
  margin: 0 0 1rem 0;
  opacity: 0.6;
`;

export const Categories = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0px 4px 6px 0;
  }
`;

export const StyledPopup = styled(Popup)`
  z-index: 9999;

  .mapboxgl-popup-tip {
    border-bottom-color: white;
  }

  .mapboxgl-popup-content {
    background-color: white;
    border-radius: ${(props) => props.theme.borderRadius};
    box-shadow: ${(props) => props.theme.cardBoxShadow};
    padding: 18px;
  }

  .mapboxgl-popup-close-button {
    font-size: 20px;
    font-family: ${(props) => props.theme.bodyFont};
    opacity: 0.5;

    &:hover {
      background-color: rgba(0, 0, 0, 0);
      opacity: 0.8;
    }
  }
`;

export const ConfidenceLabel = styled(Chip)`
  transition: background-color 0s !important;
  color: ${(props) => {
    if (typeof props?.label !== 'string') return 'black';
    if (props?.label?.includes('Medium')) return '#222';
    return 'white';
  }} !important;
  background-color: ${(props) => {
    if (typeof props?.label !== 'string') return 'gray';
    if (props?.label?.includes('Low')) return props.theme.danger2;
    if (props?.label?.includes('Medium')) return '#edde32';
    if (props?.label?.includes('High')) return props.theme.green2;
    return 'gray';
  }} !important;
`;
