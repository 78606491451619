import React from 'react';
import { Route } from 'react-router-dom';
import { SectionNavigationBar } from 'elements';
import OrganizationExport from '../OrganizationExport/OrganizationExport';

const Settings = () => {
  return (
    <>
      <SectionNavigationBar
        title="Organization"
        routes={[
          {
            route: '/organization',
            displayName: 'Export',
          },
        ]}
      />
      <Route exact path="/organization">
        <OrganizationExport />
      </Route>
    </>
  );
};

export default Settings;
