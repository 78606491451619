import { API, graphqlOperation } from 'aws-amplify';
import { updateOrganization } from 'graphql/mutations';
import { TrackOption, UpdateOrganizationMutation } from 'API';

type SubmitOrganizationSettingsInput = {
  id: string;
  alert_time_hours?: number | null;
  trackOption?: TrackOption | null;
  push_notify?: number | null;
};

const submitOrganizationSettings = async (
  { id, alert_time_hours, trackOption, push_notify }: SubmitOrganizationSettingsInput,
  organization: string,
) => {
  // fetch users by group
  const { data } = (await API.graphql(
    graphqlOperation(updateOrganization, {
      input: {
        organization,
        alert_time_hours,
        push_notify,
        trackOption,
        id,
      },
      first: 1000,
    }),
  )) as {
    data: UpdateOrganizationMutation;
  };

  return data;
};

export default submitOrganizationSettings;
