import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { Header, Body as SubHeader } from 'elements';

export const MaterialDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 8px 0;
    background-color: white;
    border-radius: ${(props) => props.theme.borderRadius};
  }
`;

export const Container = styled.div`
  padding: 0 0 16px 0;
  background-color: white;
  border-radius: ${(props) => props.theme.borderRadius};
`;

export const Title = styled(Header)`
  margin: 0;
  padding: 16px 32px;
`;

export const OrganizationName = styled(SubHeader)`
  margin: 0;
  padding: 8px 12px;
`;
