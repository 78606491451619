import React from 'react';
import { Feature, Point, GeoJsonProperties } from 'geojson';
import moment from 'moment-timezone';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import { Categories, StyledPopup, Title, Timestamp, TopRow, ConfidenceLabel } from './Popup.styled';

type PopupProps = {
  popupInfo: Feature<Point, GeoJsonProperties> | null;
  setPopupInfo: React.Dispatch<React.SetStateAction<Feature<Point, GeoJsonProperties> | null>>;
};

const Popup = React.memo(({ popupInfo, setPopupInfo }: PopupProps) => {
  if (!popupInfo) return null;

  const confidenceEnum: { [key: number]: string } = { 1: 'low', 2: 'medium', 3: 'high' };

  return (
    <StyledPopup
      tipSize={5}
      anchor="top"
      longitude={popupInfo.geometry.coordinates[0]}
      latitude={popupInfo.geometry.coordinates[1]}
      closeOnClick={false}
      onClose={() => setPopupInfo(null)}
    >
      <TopRow>
        <Title>{popupInfo?.properties?.place?.name ?? popupInfo?.properties?.id} </Title>
        {confidenceEnum[popupInfo?.properties?.confidence] && (
          <ConfidenceLabel
            label={_.startCase(`${confidenceEnum[popupInfo?.properties?.confidence]} Confidence`)}
            size="small"
          />
        )}
      </TopRow>
      <Timestamp>
        Entered at{' '}
        {`${moment(popupInfo?.properties?.timestamp).format('LLLL')} - ${moment.tz.guess()}` ||
          'N/A'}
      </Timestamp>
      <Categories>
        {popupInfo?.properties?.place?.categories.map((category: string) => {
          return <Chip key={category} label={category} />;
        })}
      </Categories>
    </StyledPopup>
  );
});

export default Popup;
