export const queryRadarEventsByUserTimestampRange = /* GraphQL */ `
  query QueryRadarEventsByUserTimestampRange(
    $user: String!
    $startDate: AWSDateTime
    $endDate: AWSDateTime
  ) {
    queryRadarEventsByUserTimestampRange(user: $user, startDate: $startDate, endDate: $endDate) {
      message
      error
      url
      items {
        eventType
        timestamp
        user
        place {
          name
          categories
          location {
            coordinates
            type
          }
          chain {
            name
            slug
            externalId
            metadata
          }
        }
        insights {
          state {
            home
            office
            traveling
            commuting
          }
          locations {
            type
            location {
              coordinates
            }
            confidence
          }
        }
        userLocation {
          coordinates
          type
        }
        locationMeta {
          accuracy
          altitude
          course
          courseAccuracy
          latitude
          longitude
          speed
          speedAccuracy
          verticalAccuracy
        }
      }
    }
  }
`;

export const queryRadarEventsByOrganizationTimestampRange = /* GraphQL */ `
  query QueryRadarEventsByOrganizationTimestampRange(
    $organization: String!
    $startDate: AWSDateTime
    $endDate: AWSDateTime
  ) {
    queryRadarEventsByOrganizationTimestampRange(
      organization: $organization
      startDate: $startDate
      endDate: $endDate
    ) {
      message
      error
      url
    }
  }
`;

export const queryVerifiedNativeUsersByOrganizationActiveIndex = /* GraphQL */ `
  query QueryVerifiedNativeUsersByOrganizationActiveIndex(
    $organization: String!
    $first: Int
    $after: String
  ) {
    queryVerifiedNativeUsersByOrganizationActiveIndex(
      organization: $organization
      first: $first
      after: $after
    ) {
      items {
        user
        organization
        activatedBy
        active
        activeDate
        userId
        cancelDate
        deviceMeta {
          deviceId
          systemVersion
          carrier
          locationProviders
          Platform
          appVersion
          model
        }
      }
      nextToken
    }
  }
`;
