/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRadarEvents = /* GraphQL */ `
  query GetRadarEvents($user: String!, $timestamp: AWSDateTime!) {
    getRadarEvents(user: $user, timestamp: $timestamp) {
      eventType
      organization
      timestamp
      user
      createdAt
      deviceId
      eventId
      eventLocation {
        coordinates
        type
      }
      locationMeta {
        accuracy
        altitude
        course
        courseAccuracy
        latitude
        longitude
        speed
        speedAccuracy
        verticalAccuracy
      }
      radarUserId
      raw
      ttl
      updatedAt
      userLocation {
        coordinates
        type
      }
      place {
        name
        categories
        location {
          coordinates
          type
        }
        chain {
          name
          slug
          externalId
          metadata
        }
      }
      insights {
        state {
          home
          office
          traveling
          commuting
        }
        locations {
          type
          location {
            coordinates
            type
          }
          coordinates
          confidence
          updatedAt
        }
      }
      confidence
    }
  }
`;
export const listRadarEvents = /* GraphQL */ `
  query ListRadarEvents(
    $filter: TableRadarEventsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRadarEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        eventType
        organization
        timestamp
        user
        createdAt
        deviceId
        eventId
        eventLocation {
          coordinates
          type
        }
        locationMeta {
          accuracy
          altitude
          course
          courseAccuracy
          latitude
          longitude
          speed
          speedAccuracy
          verticalAccuracy
        }
        radarUserId
        raw
        ttl
        updatedAt
        userLocation {
          coordinates
          type
        }
        place {
          name
          categories
          location {
            coordinates
            type
          }
          chain {
            name
            slug
            externalId
            metadata
          }
        }
        insights {
          state {
            home
            office
            traveling
            commuting
          }
          locations {
            type
            coordinates
            confidence
            updatedAt
          }
        }
        confidence
      }
      nextToken
    }
  }
`;
export const queryRadarEventsByOrganizationTimestampIndex = /* GraphQL */ `
  query QueryRadarEventsByOrganizationTimestampIndex(
    $organization: String!
    $first: Int
    $after: String
  ) {
    queryRadarEventsByOrganizationTimestampIndex(
      organization: $organization
      first: $first
      after: $after
    ) {
      items {
        eventType
        organization
        timestamp
        user
        createdAt
        deviceId
        eventId
        eventLocation {
          coordinates
          type
        }
        locationMeta {
          accuracy
          altitude
          course
          courseAccuracy
          latitude
          longitude
          speed
          speedAccuracy
          verticalAccuracy
        }
        radarUserId
        raw
        ttl
        updatedAt
        userLocation {
          coordinates
          type
        }
        place {
          name
          categories
          location {
            coordinates
            type
          }
          chain {
            name
            slug
            externalId
            metadata
          }
        }
        insights {
          state {
            home
            office
            traveling
            commuting
          }
          locations {
            type
            coordinates
            confidence
            updatedAt
          }
        }
        confidence
      }
      nextToken
    }
  }
`;
export const queryRadarEventsByEventTypeTimestampIndex = /* GraphQL */ `
  query QueryRadarEventsByEventTypeTimestampIndex(
    $eventType: String!
    $first: Int
    $after: String
  ) {
    queryRadarEventsByEventTypeTimestampIndex(
      eventType: $eventType
      first: $first
      after: $after
    ) {
      items {
        eventType
        organization
        timestamp
        user
        createdAt
        deviceId
        eventId
        eventLocation {
          coordinates
          type
        }
        locationMeta {
          accuracy
          altitude
          course
          courseAccuracy
          latitude
          longitude
          speed
          speedAccuracy
          verticalAccuracy
        }
        radarUserId
        raw
        ttl
        updatedAt
        userLocation {
          coordinates
          type
        }
        place {
          name
          categories
          location {
            coordinates
            type
          }
          chain {
            name
            slug
            externalId
            metadata
          }
        }
        insights {
          state {
            home
            office
            traveling
            commuting
          }
          locations {
            type
            coordinates
            confidence
            updatedAt
          }
        }
        confidence
      }
      nextToken
    }
  }
`;
export const queryRadarEventsByUserTimestampRange = /* GraphQL */ `
  query QueryRadarEventsByUserTimestampRange(
    $user: String!
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $activity: Boolean
  ) {
    queryRadarEventsByUserTimestampRange(
      user: $user
      startDate: $startDate
      endDate: $endDate
      activity: $activity
    ) {
      message
      error
      url
      items {
        eventType
        organization
        timestamp
        user
        createdAt
        deviceId
        eventId
        eventLocation {
          coordinates
          type
        }
        locationMeta {
          accuracy
          altitude
          course
          courseAccuracy
          latitude
          longitude
          speed
          speedAccuracy
          verticalAccuracy
        }
        radarUserId
        raw
        ttl
        updatedAt
        userLocation {
          coordinates
          type
        }
        place {
          name
          categories
          location {
            coordinates
            type
          }
          chain {
            name
            slug
            externalId
            metadata
          }
        }
        insights {
          state {
            home
            office
            traveling
            commuting
          }
          locations {
            type
            coordinates
            confidence
            updatedAt
          }
        }
        confidence
      }
    }
  }
`;
export const queryRadarEventsByOrganizationTimestampRange = /* GraphQL */ `
  query QueryRadarEventsByOrganizationTimestampRange(
    $organization: String!
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $activity: Boolean
  ) {
    queryRadarEventsByOrganizationTimestampRange(
      organization: $organization
      startDate: $startDate
      endDate: $endDate
      activity: $activity
    ) {
      message
      error
      url
      items {
        eventType
        organization
        timestamp
        user
        createdAt
        deviceId
        eventId
        eventLocation {
          coordinates
          type
        }
        locationMeta {
          accuracy
          altitude
          course
          courseAccuracy
          latitude
          longitude
          speed
          speedAccuracy
          verticalAccuracy
        }
        radarUserId
        raw
        ttl
        updatedAt
        userLocation {
          coordinates
          type
        }
        place {
          name
          categories
          location {
            coordinates
            type
          }
          chain {
            name
            slug
            externalId
            metadata
          }
        }
        insights {
          state {
            home
            office
            traveling
            commuting
          }
          locations {
            type
            coordinates
            confidence
            updatedAt
          }
        }
        confidence
      }
    }
  }
`;
export const getNativeUsers = /* GraphQL */ `
  query GetNativeUsers($user: String!, $deviceId: String) {
    getNativeUsers(user: $user, deviceId: $deviceId) {
      deviceId
      user
      userId
      organization
      radarUserId
    }
  }
`;
export const queryRadarApi = /* GraphQL */ `
  query QueryRadarApi(
    $query: String
    $path: String!
    $body: String
    $method: Methods
  ) {
    queryRadarApi(query: $query, path: $path, body: $body, method: $method)
  }
`;
export const listNativeUsers = /* GraphQL */ `
  query ListNativeUsers(
    $filter: TableNativeUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNativeUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        deviceId
        user
        userId
        organization
        radarUserId
      }
      nextToken
    }
  }
`;
export const queryNativeUsersByDeviceIdUserIndex = /* GraphQL */ `
  query QueryNativeUsersByDeviceIdUserIndex(
    $deviceId: String!
    $first: Int
    $after: String
  ) {
    queryNativeUsersByDeviceIdUserIndex(
      deviceId: $deviceId
      first: $first
      after: $after
    ) {
      items {
        deviceId
        user
        userId
        organization
        radarUserId
      }
      nextToken
    }
  }
`;
export const queryNativeUsersByPlayerIdIndex = /* GraphQL */ `
  query QueryNativeUsersByPlayerIdIndex(
    $userId: String!
    $first: Int
    $after: String
  ) {
    queryNativeUsersByPlayerIdIndex(
      userId: $userId
      first: $first
      after: $after
    ) {
      items {
        deviceId
        user
        userId
        organization
        radarUserId
      }
      nextToken
    }
  }
`;
export const queryNativeUsersByOrganizationIndex = /* GraphQL */ `
  query QueryNativeUsersByOrganizationIndex(
    $organization: String!
    $first: Int
    $after: String
  ) {
    queryNativeUsersByOrganizationIndex(
      organization: $organization
      first: $first
      after: $after
    ) {
      items {
        deviceId
        user
        userId
        organization
        radarUserId
      }
      nextToken
    }
  }
`;
export const queryNativeUsersByPartitionKey = /* GraphQL */ `
  query QueryNativeUsersByPartitionKey($user: String!) {
    queryNativeUsersByPartitionKey(user: $user) {
      items {
        deviceId
        user
        userId
        organization
        radarUserId
      }
      nextToken
    }
  }
`;
export const getDailyQuestion = /* GraphQL */ `
  query GetDailyQuestion($user: String!, $timestamp: AWSDateTime!) {
    getDailyQuestion(user: $user, timestamp: $timestamp) {
      user
      timestamp
      anxiety
      bodyTemperature
      cough
      difficultyBreathing
      lossOfTasteSmell
      soreThroat
      achingLimbs
      recovery
      sleepQuality
      organization
      oxygen
    }
  }
`;
export const listDailyQuestions = /* GraphQL */ `
  query ListDailyQuestions(
    $filter: TableDailyQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDailyQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        user
        timestamp
        anxiety
        bodyTemperature
        cough
        difficultyBreathing
        lossOfTasteSmell
        soreThroat
        achingLimbs
        recovery
        sleepQuality
        organization
        oxygen
      }
      nextToken
    }
  }
`;
export const queryDailyQuestionsByOrganizationTimestampIndex = /* GraphQL */ `
  query QueryDailyQuestionsByOrganizationTimestampIndex(
    $organization: String!
    $first: Int
    $after: String
  ) {
    queryDailyQuestionsByOrganizationTimestampIndex(
      organization: $organization
      first: $first
      after: $after
    ) {
      items {
        user
        timestamp
        anxiety
        bodyTemperature
        cough
        difficultyBreathing
        lossOfTasteSmell
        soreThroat
        achingLimbs
        recovery
        sleepQuality
        organization
        oxygen
      }
      nextToken
    }
  }
`;
export const queryDailyQuestionsByUser = /* GraphQL */ `
  query QueryDailyQuestionsByUser($user: String!, $first: Int, $after: String) {
    queryDailyQuestionsByUser(user: $user, first: $first, after: $after) {
      items {
        user
        timestamp
        anxiety
        bodyTemperature
        cough
        difficultyBreathing
        lossOfTasteSmell
        soreThroat
        achingLimbs
        recovery
        sleepQuality
        organization
        oxygen
      }
      nextToken
    }
  }
`;
export const getVerifiedNativeUsers = /* GraphQL */ `
  query GetVerifiedNativeUsers($user: String!, $organization: String!) {
    getVerifiedNativeUsers(user: $user, organization: $organization) {
      user
      organization
      activatedBy
      active
      activeDate
      userId
      cancelDate
      deviceMeta {
        deviceId
        systemVersion
        carrier
        locationProviders
        Platform
        deviceToken
        appVersion
        model
      }
      pushNotifications
    }
  }
`;
export const listVerifiedNativeUsers = /* GraphQL */ `
  query ListVerifiedNativeUsers(
    $filter: TableVerifiedNativeUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerifiedNativeUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user
        organization
        activatedBy
        active
        activeDate
        userId
        cancelDate
        deviceMeta {
          deviceId
          systemVersion
          carrier
          locationProviders
          Platform
          deviceToken
          appVersion
          model
        }
        pushNotifications
      }
      nextToken
    }
  }
`;
export const queryVerifiedNativeUsersByOrganizationActiveIndex = /* GraphQL */ `
  query QueryVerifiedNativeUsersByOrganizationActiveIndex(
    $organization: String!
    $first: Int
    $after: String
  ) {
    queryVerifiedNativeUsersByOrganizationActiveIndex(
      organization: $organization
      first: $first
      after: $after
    ) {
      items {
        user
        organization
        activatedBy
        active
        activeDate
        userId
        cancelDate
        deviceMeta {
          deviceId
          systemVersion
          carrier
          locationProviders
          Platform
          deviceToken
          appVersion
          model
        }
        pushNotifications
      }
      nextToken
    }
  }
`;
export const queryVerifiedNativeUsersByUser = /* GraphQL */ `
  query QueryVerifiedNativeUsersByUser(
    $user: String!
    $first: Int
    $after: String
  ) {
    queryVerifiedNativeUsersByUser(user: $user, first: $first, after: $after) {
      items {
        user
        organization
        activatedBy
        active
        activeDate
        userId
        cancelDate
        deviceMeta {
          deviceId
          systemVersion
          carrier
          locationProviders
          Platform
          deviceToken
          appVersion
          model
        }
        pushNotifications
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($organization: String!, $id: ID) {
    getOrganization(organization: $organization, id: $id) {
      organization
      id
      alert_time_hours
      push_notify
      alertEmails
      trackOption
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: TableOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        organization
        id
        alert_time_hours
        push_notify
        alertEmails
        trackOption
      }
      nextToken
    }
  }
`;
export const queryOrganizationsByIdIndex = /* GraphQL */ `
  query QueryOrganizationsByIdIndex($id: ID!, $first: Int, $after: String) {
    queryOrganizationsByIdIndex(id: $id, first: $first, after: $after) {
      items {
        organization
        id
        alert_time_hours
        push_notify
        alertEmails
        trackOption
      }
      nextToken
    }
  }
`;
export const queryOrganization = /* GraphQL */ `
  query QueryOrganization($organization: String!, $first: Int, $after: String) {
    queryOrganization(
      organization: $organization
      first: $first
      after: $after
    ) {
      items {
        organization
        id
        alert_time_hours
        push_notify
        alertEmails
        trackOption
      }
      nextToken
    }
  }
`;
export const getDevices = /* GraphQL */ `
  query GetDevices($model: String!) {
    getDevices(model: $model) {
      model
      brand
      name
      device
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: TableDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        model
        brand
        name
        device
      }
      nextToken
    }
  }
`;
export const queryDevicesByDeviceIndex = /* GraphQL */ `
  query QueryDevicesByDeviceIndex(
    $device: String!
    $first: Int
    $after: String
  ) {
    queryDevicesByDeviceIndex(device: $device, first: $first, after: $after) {
      items {
        model
        brand
        name
        device
      }
      nextToken
    }
  }
`;
