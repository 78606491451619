import React from 'react';
import { LoadingIcon, SubHeader } from 'elements';
import { useParams } from 'react-router-dom';
import { Center } from 'styles/utils';
import { DailyQuestionsResult } from 'components/user';
import { useDailyQuestions } from 'components/user/__hooks__';
import { SubHeaderBar, CreateButton, List } from './DailyQuestions.styled';

const DailyQuestionsList = () => {
  const { id: currentUserID } = useParams();
  const {
    list: { isLoading, data: dailyQuestions },
  } = useDailyQuestions();

  if (isLoading) {
    return <LoadingIcon />;
  }

  return (
    <>
      <SubHeaderBar>
        <CreateButton to={`/user/${currentUserID}/daily-questions/submit`}>Create New</CreateButton>
      </SubHeaderBar>
      {dailyQuestions && dailyQuestions.length !== 0 ? (
        <List>
          {dailyQuestions.map((result) => (
            <DailyQuestionsResult key={result.timestamp} result={result} />
          ))}
        </List>
      ) : (
        <Center>
          <SubHeader>No daily questions have been submitted for this user.</SubHeader>
        </Center>
      )}
    </>
  );
};

export default DailyQuestionsList;
