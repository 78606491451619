import styled from 'styled-components';

type TypographyProps = {
  secondary?: boolean;
  noMargins?: boolean;
};

export const Logo = styled.h2<TypographyProps>`
  font-family: ${(props) => props.theme.headerFont};
  font-size: 2.3rem;
  font-weight: bold;
  margin-bottom: 64px;
  width: 100%;
  text-align: center;
  background: -webkit-linear-gradient(20deg, #0098c6 10%, #49c0ad);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Header = styled.h2<TypographyProps>`
  font-family: ${(props) => props.theme.headerFont};
  font-size: 1.5rem;
  font-weight: 400;
`;

export const SubHeader = styled.p<TypographyProps>`
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 1.1rem;
  font-weight: 400;

  ${(props) =>
    props.secondary &&
    `
    opacity: 0.54;
  `}
`;

export const Body = styled.p<TypographyProps>`
  font-family: ${(props) => props.theme.bodyFont};
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0.3rem 0 1rem 0;

  ${(props) =>
    props.secondary &&
    `
    opacity: 0.54;
  `}

  ${(props) =>
    props.noMargins &&
    `
    margin: 0;
  `}
`;
