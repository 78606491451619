import React from 'react';
import UserInformation from '../UserInformation/UserInformation';
import NotificationBell from '../NotificationBell/NotificationBell';
import { Container } from './Header.styled';

const Header = () => {
  return (
    <Container>
      <UserInformation />
      <NotificationBell />
    </Container>
  );
};

export default Header;
