import React from 'react';
import { Marker } from 'react-map-gl';
import { FeatureCollection, Point, GeoJsonProperties, Feature } from 'geojson';
import { PinContainer, HomeIcon, PlaceIcon, WorkIcon } from './Pins.styled';

const SIZE = 30;

type PinsProps = {
  data: FeatureCollection<Point, GeoJsonProperties>;
  type: 'home' | 'place' | 'work';
  onClick: React.Dispatch<React.SetStateAction<Feature<Point, GeoJsonProperties> | null>>;
};

// important for perf: the markers never change, avoid rerender when the map viewport changes
const Pins = React.memo(({ data, type, onClick }: PinsProps) => {
  return (
    <>
      {data.features.map((feature) => {
        return (
          <Marker
            key={`marker-${feature.geometry.coordinates[0]}-${feature.geometry.coordinates[1]}`}
            longitude={feature.geometry.coordinates[0]}
            latitude={feature.geometry.coordinates[1]}
          >
            <PinContainer
              size={SIZE}
              type={type}
              onClick={() => {
                // only show popup for location pins
                if (feature?.properties?.place?.name) {
                  onClick(feature);
                }
              }}
            >
              {type === 'home' && <HomeIcon data-testid="home-icon" />}
              {type === 'place' && <PlaceIcon data-testid="place-icon" />}
              {type === 'work' && <WorkIcon data-testid="work-icon" />}
            </PinContainer>
          </Marker>
        );
      })}
    </>
  );
});

export default Pins;
