import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { SubHeader } from '../Typography/Typography';
import { Container } from './Modal.styled';

type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  onClose?: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
};

const Modal = ({ children, open, onClose }: ModalProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {children}
    </Dialog>
  );
};

export const ModalHeader = SubHeader;

export const ModalContent = Container;

export const ModalActions = DialogActions;

export default Modal;
