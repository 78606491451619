import React from 'react';
import { Route } from 'react-router-dom';
import { SectionNavigationBar } from 'elements';
import Organization from '../Organization/Organization';
import Users from '../Users/Users';

const Settings = () => {
  return (
    <>
      <SectionNavigationBar
        title="Settings"
        routes={[
          { route: '/settings', displayName: 'Organization' },
          {
            route: '/settings/users',
            displayName: 'Users',
          },
        ]}
      />
      <Route exact path="/settings">
        <Organization />
      </Route>

      <Route exact path="/settings/users">
        <Users />
      </Route>
    </>
  );
};

export default Settings;
