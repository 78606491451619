import React from 'react';
import { Route, useParams } from 'react-router-dom';
import { SectionNavigationBar } from 'elements';
import { useDateRange } from 'context/date-range';
import { useUserProfile } from 'components/user/__hooks__';
import Overview from '../Overview/Overview';
import Activity from '../Activity/Activity';
import DailyQuestions from '../DailyQuestions/DailyQuestions';
import DailyQuestionsForm from '../DailyQuestionsForm/DailyQuestionsForm';
import Export from '../Export/Export';

const UserProfile = () => {
  const [dateRange] = useDateRange();
  const { id: currentUserID } = useParams();
  const { user } = useUserProfile({ dateRange });

  return (
    <>
      <SectionNavigationBar
        title={user?.user || ''}
        routes={[
          {
            route: `/user/${currentUserID}`,
            displayName: 'Overview',
          },
          {
            route: `/user/${currentUserID}/activity`,
            displayName: 'Activity',
          },
          {
            route: `/user/${currentUserID}/daily-questions`,
            displayName: 'Daily Questions',
          },
          {
            route: `/user/${currentUserID}/export`,
            displayName: 'Export',
          },
        ]}
      />
      <Route exact path="/user/:id">
        <Overview />
      </Route>
      <Route exact path="/user/:id/activity">
        <Activity />
      </Route>
      <Route exact path="/user/:id/daily-questions">
        <DailyQuestions />
      </Route>
      <Route exact path="/user/:id/export">
        <Export />
      </Route>
      <Route exact path="/user/:id/daily-questions/submit">
        <DailyQuestionsForm />
      </Route>
    </>
  );
};

export default UserProfile;
