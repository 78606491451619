import { useQuery, useMutation, QueryResult, MutationResult, MutateFunction } from 'react-query';
import {
  CreateVerifiedNativeUsersInput,
  UpdateVerifiedNativeUsersInput,
  CreateVerifiedNativeUsersMutation,
  UpdateVerifiedNativeUsersMutation,
  DeleteVerifiedNativeUsersInput,
  DeleteVerifiedNativeUsersMutation,
} from 'API';
import { useOrganization } from 'context/organization';
import setActiveStatus from './utils/setActiveStatus';
import fetchUserList from './utils/fetchUserList';
import createUser from './utils/createUser';
import deleteUser from './utils/deleteUser';

type useUsers = () => {
  list: QueryResult<CreateVerifiedNativeUsersInput[]>;
  setActiveStatus: [
    MutateFunction<UpdateVerifiedNativeUsersMutation, { formData: UpdateVerifiedNativeUsersInput }>,
    MutationResult<UpdateVerifiedNativeUsersMutation>,
  ];
  deleteUser: [
    MutateFunction<DeleteVerifiedNativeUsersMutation, { input: DeleteVerifiedNativeUsersInput }>,
    MutationResult<DeleteVerifiedNativeUsersMutation>,
  ];

  createUser: [
    MutateFunction<
      CreateVerifiedNativeUsersMutation,
      {
        user: string;
      }
    >,
    MutationResult<CreateVerifiedNativeUsersMutation>,
  ];
};

const useUsers: useUsers = () => {
  // get context for current organization
  const [organization] = useOrganization();

  // mutation for changing status of user
  const setActiveStatusMutation = useMutation<
    UpdateVerifiedNativeUsersMutation,
    { formData: UpdateVerifiedNativeUsersInput }
  >(setActiveStatus);

  // mutation for creating a new user
  const createUserMutation = useMutation<
    CreateVerifiedNativeUsersMutation,
    {
      user: string;
    }
  >(({ user }) => createUser({ user, organization }));

  // mutation for deleting a user
  const deleteUserMutation = useMutation<
    DeleteVerifiedNativeUsersMutation,
    { input: DeleteVerifiedNativeUsersInput }
  >(deleteUser);

  // pull list of users
  const listQuery = useQuery(['fetchUserList', organization], fetchUserList);

  return {
    list: listQuery,
    setActiveStatus: setActiveStatusMutation,
    createUser: createUserMutation,
    deleteUser: deleteUserMutation,
  };
};

export default useUsers;
