import styled from 'styled-components';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const DateHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;
  box-sizing: border-box;
  height: 64px;
`;

export const StartDatePicker = styled(KeyboardDatePicker)`
  margin-right: 16px !important;
`;

export const EndDatePicker = styled(KeyboardDatePicker)``;
