import { useParams } from 'react-router-dom';
import { CreateVerifiedNativeUsersInput, CreateRadarEventsInput } from 'API';
import { useQuery, QueryResult } from 'react-query';
import { useUsersList } from 'context/users';
import fetchUserData from './utils/fetchUserData';

type useUserProfile = (props: {
  dateRange: {
    start: moment.Moment;
    end: moment.Moment;
  };
}) => {
  user: CreateVerifiedNativeUsersInput | undefined;
  userData: QueryResult<CreateRadarEventsInput[]>;
};

const useUserProfile: useUserProfile = ({ dateRange }) => {
  const { id: currentUserID } = useParams();
  const { data: users } = useUsersList();
  const currentUser = users?.find(({ user }) => user === currentUserID);
  const query = useQuery<
    CreateRadarEventsInput[],
    [
      string,
      string | undefined,
      {
        start: moment.Moment;
        end: moment.Moment;
      },
    ]
  >(currentUser && ['userData', currentUser.user, dateRange], fetchUserData);

  return {
    user: currentUser,
    userData: query,
  };
};

export default useUserProfile;
