/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useDailyQuestions } from 'components/user/__hooks__';
import { Button, Slider, TimestampPicker } from 'elements';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { DailyQuestionsForm as DailyQuestionsFormType } from 'types';
import Select from '@material-ui/core/NativeSelect';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'context/alert';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import {
  Container,
  Grid,
  Control,
  SliderContainer,
  SubmitButton,
} from './DailyQuestionsForm.styled';

const DailyQuestionsForm = () => {
  const {
    list: { refetch },
    submit: { submitDailyQuestions, isSuccess, isError, reset },
  } = useDailyQuestions();
  const history = useHistory();
  const { handleSubmit, errors, control } = useForm<DailyQuestionsFormType>();
  const [submitting, setSubmitting] = useState(false);
  const { successAlert, errorAlert } = useAlert();

  useEffect(() => {
    // if success, refetch data then go back to daily questions list
    if (isSuccess) {
      refetch({}).then(() => {
        setSubmitting(false);
        history.goBack();
        reset();
        successAlert({ message: 'Successfully submitted daily questions' });
      });
    }

    // if no response, then throw an error
    if (isError) {
      errorAlert({ message: 'Failed to submit daily questions' });
      setSubmitting(false);
    }
  }, [isSuccess, isError, refetch, history, successAlert, errorAlert, reset]);

  return (
    <Container>
      <form
        onSubmit={handleSubmit(async (formData) => {
          setSubmitting(true);
          submitDailyQuestions({ formData });
        })}
      >
        <Grid>
          <Controller
            as={TimestampPicker}
            control={control}
            id="timestamp"
            name="timestamp"
            label="Timestamp"
            defaultValue={moment()}
            rules={{
              required: true,
              validate: (input) => moment(input).isValid(),
            }}
          />

          <FormControl margin="normal">
            <InputLabel htmlFor="lossOfTasteSmell">Loss of sense of taste/smell</InputLabel>
            <Controller
              as={Select}
              error={!!errors.lossOfTasteSmell}
              required
              control={control}
              id="lossOfTasteSmell"
              name="lossOfTasteSmell"
              defaultValue="no"
              fullWidth
              autoComplete="off"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </Controller>
          </FormControl>

          <FormControl margin="normal">
            <InputLabel htmlFor="cough">Cough</InputLabel>
            <Controller
              as={Select}
              error={!!errors.cough}
              required
              control={control}
              id="cough"
              name="cough"
              defaultValue="no"
              fullWidth
              autoComplete="off"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </Controller>
          </FormControl>

          <FormControl margin="normal">
            <InputLabel htmlFor="difficultyBreathing">Difficulties in breathing</InputLabel>
            <Controller
              as={Select}
              error={!!errors.difficultyBreathing}
              required
              control={control}
              id="difficultyBreathing"
              name="difficultyBreathing"
              defaultValue="no"
              fullWidth
              autoComplete="off"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </Controller>
          </FormControl>

          <FormControl margin="normal">
            <InputLabel htmlFor="soreThroat">Sore throat</InputLabel>
            <Controller
              as={Select}
              error={!!errors.soreThroat}
              required
              control={control}
              id="soreThroat"
              name="soreThroat"
              defaultValue="no"
              fullWidth
              autoComplete="off"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </Controller>
          </FormControl>

          <FormControl margin="normal">
            <InputLabel htmlFor="achingLimbs">Aching limbs</InputLabel>
            <Controller
              as={Select}
              error={!!errors.achingLimbs}
              required
              control={control}
              id="achingLimbs"
              name="achingLimbs"
              defaultValue="no"
              fullWidth
              autoComplete="off"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </Controller>
          </FormControl>

          <Controller
            as={TextField}
            error={!!errors.bodyTemperature}
            required
            control={control}
            rules={{ pattern: /^[0-9]+(\.[0-9]{1,3})?$/i }}
            id="bodyTemperature"
            name="bodyTemperature"
            label="Bodily temperature (ºC)"
            defaultValue=""
            type="text"
            margin="normal"
            fullWidth
            autoComplete="off"
            InputLabelProps={{ required: false }}
          />

          <Controller
            as={TextField}
            error={!!errors.oxygen}
            required
            control={control}
            rules={{ pattern: /^[0-9]+(\.[0-9]{1,3})?$/i }}
            id="oxygen"
            name="oxygen"
            label="Sp02 (%)"
            defaultValue=""
            type="text"
            margin="normal"
            fullWidth
            autoComplete="off"
            InputLabelProps={{ required: false }}
          />

          <SliderContainer>
            <InputLabel>Sleep quality</InputLabel>
            <Controller
              as={Slider}
              control={control}
              id="sleepQuality"
              name="sleepQuality"
              defaultValue={6}
              min={1}
              max={10}
              step={1}
              onChange={([, value]) => value}
              leftLabels={['Good sleep', 'Quickly fell asleep', 'Slept through the night']}
              middleLabels={['Restless sleep', 'Long time lying awake', 'Sensitive sleep']}
              rightLabels={['Bad sleep', 'Problems falling asleep', 'Several night-time wake-ups']}
            />
          </SliderContainer>

          <SliderContainer>
            <InputLabel>Level of recovery</InputLabel>
            <Controller
              as={Slider}
              control={control}
              id="recovery"
              name="recovery"
              defaultValue={6}
              min={1}
              max={10}
              step={1}
              onChange={([, value]) => value}
              leftLabels={['Energetic', 'Cheerful', 'Rested']}
              middleLabels={['Drowsy', 'Passive', 'Fatigued']}
              rightLabels={['Tired', 'Unenthusiastic', 'Exhausted']}
            />
          </SliderContainer>

          <SliderContainer>
            <InputLabel>Anxiety</InputLabel>
            <Controller
              as={Slider}
              control={control}
              id="anxiety"
              name="anxiety"
              defaultValue={6}
              min={1}
              max={10}
              step={1}
              onChange={([, value]) => value}
              leftLabels={['Calm', 'Composed', 'Unconcerned']}
              middleLabels={['Uneasiness', 'Worried', 'Concerned']}
              rightLabels={['Panicked', 'Anxious', 'Severe risk']}
            />
          </SliderContainer>
        </Grid>
        <Control>
          <SubmitButton loading={submitting} htmlType="submit">
            Submit
          </SubmitButton>
          <Button loading={submitting} type="text" to="previous">
            Cancel
          </Button>
        </Control>
      </form>
    </Container>
  );
};

export default DailyQuestionsForm;
