import React from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { LocationMetaDataInput, CreateRadarEventsInput, CreateDailyQuestionInput } from 'API';
import { Container, EntriesLabel, Csv, ExportButton } from './useUserCsv.styled';

interface CsvData extends LocationMetaDataInput {
  userid: string;
  timestamp: string;
}

type useUserCsvProps = {
  locationData: CreateRadarEventsInput[] | undefined;
  dailyQuestionsData: CreateDailyQuestionInput[] | undefined;
  dateRange: {
    start: moment.Moment;
    end: moment.Moment;
  };
};

const ExportLocationData = ({
  locationData,
}: {
  locationData: CreateRadarEventsInput[] | undefined;
}) => {
  const { id: currentUserID } = useParams();

  const locationCsv: CsvData[] = locationData
    ?.filter((item) => item !== null && item.eventType === 'location')
    .map((item) => ({
      user: item.user,
      timestamp: moment(item.timestamp).format('DD/MM/YYYY-HH:mmZ'),
      latitude: item.locationMeta?.latitude,
      longitude: item.locationMeta?.longitude,
      altitude: item.locationMeta?.altitude,
      accuracy: item.locationMeta?.accuracy,
    })) as [];

  return (
    <Container>
      <Csv filename={`${currentUserID}-${moment().format()}-location.csv`} data={locationCsv}>
        <ExportButton>Export Location Data</ExportButton>
      </Csv>
      <EntriesLabel>{`${locationCsv.length} entries`}</EntriesLabel>
    </Container>
  );
};

const ExportActivity = ({
  locationData,
}: {
  locationData: CreateRadarEventsInput[] | undefined;
}) => {
  const { id: currentUserID } = useParams();

  const activityCsv: CsvData[] = locationData
    ?.filter((item) => item !== null && item.eventType.includes('user'))
    .map((item) => {
      const locations = () =>
        item.insights?.locations
          ?.map((location) => ({
            [`${location?.type}_latitude`]: location?.coordinates?.[0],
            [`${location?.type}_longitude`]: location?.coordinates?.[1],
          }))
          .reduce((prev: object, value: object) => {
            return {
              ...prev,
              ...(value && { ...value }),
            };
          }, {});

      return {
        user: item.user,
        timestamp: moment(item.timestamp).format('DD/MM/YYYY-HH:mmZ'),
        eventType: item.eventType,
        location: item?.place?.name,
        longitude: item.place?.location?.coordinates?.[0],
        latitude: item.place?.location?.coordinates?.[1],
        categories: item?.place?.categories,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        // @ts-expect-error  confidence is returned from raw data; s3 converts raw string to object
        confidence: item?.confidence || item?.raw?.event?.confidence,
        ...locations(),
      };
    }) as [];

  return (
    <Container>
      <Csv filename={`${currentUserID}-${moment().format()}-activity.csv`} data={activityCsv}>
        <ExportButton>Export Activity Data</ExportButton>
      </Csv>
      <EntriesLabel>{`${activityCsv.length} entries`}</EntriesLabel>
    </Container>
  );
};

const ExportDailyQuestionsData = ({
  dailyQuestionsData,
  dateRange,
}: {
  dailyQuestionsData: CreateDailyQuestionInput[] | undefined;
  dateRange: {
    start: moment.Moment;
    end: moment.Moment;
  };
}) => {
  const { id: currentUserID } = useParams();

  // filter out daily questions out of date range
  const dailyQuestionsCsv = dailyQuestionsData?.filter((item) => {
    if (!item) return false;
    if (moment(item.timestamp).isBefore(moment(dateRange.start))) return false;
    if (moment(item.timestamp).isAfter(moment(dateRange.end))) return false;
    return true;
  });

  return (
    <Container>
      <Csv
        filename={`${currentUserID}-${moment().format()}-daily-questions.csv`}
        data={
          dailyQuestionsCsv?.map((item) => ({
            ...item,
            timestamp: moment(item.timestamp).format('DD/MM/YYYY-HH:mmZ'),
          })) || []
        }
      >
        <ExportButton>Export Daily Questions</ExportButton>
      </Csv>
      <EntriesLabel>{`${dailyQuestionsCsv?.length || 0} entries`}</EntriesLabel>
    </Container>
  );
};

const useUserCsv = ({ locationData, dailyQuestionsData, dateRange }: useUserCsvProps) => {
  return {
    ExportLocationData: <ExportLocationData locationData={locationData} />,
    ExportDailyQuestionsData: (
      <ExportDailyQuestionsData dailyQuestionsData={dailyQuestionsData} dateRange={dateRange} />
    ),
    ExportActivity: <ExportActivity locationData={locationData} />,
  };
};

export default useUserCsv;
