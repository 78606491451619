import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #f6f7fb;
  min-height: 100vh;
  width: 100vw;
`;

export const Content = styled.div`
  display: grid;
  flex-direction: column;
  padding: 4%;
  width: 100%;
  box-sizing: border-box;
  row-gap: 40px;
  grid-auto-rows: min-content;
`;
