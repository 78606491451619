import React from 'react';
import { Container } from './Card.styled';

const Card = ({
  children,
  noPadding = false,
  className,
}: {
  children: React.ReactElement | React.ReactElement[];
  noPadding?: boolean;
  className?: string;
}) => {
  return (
    <Container className={className} noPadding={noPadding}>
      {children}
    </Container>
  );
};

export default Card;
