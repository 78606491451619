import { useMemo } from 'react';
import { Feature, FeatureCollection, LineString, GeoJsonProperties, Point } from 'geojson';
import { CreateRadarEventsInput } from 'API';
import _ from 'lodash';
import moment from 'moment';
import { WebMercatorViewport } from 'react-map-gl';
import calculateBoundingBox from '../utils/calculateBoundingBox';

type insightsData = {
  home: FeatureCollection<GeoJSON.Point>;
  work: FeatureCollection<GeoJSON.Point>;
};

type useMapData = (
  data: CreateRadarEventsInput[] | undefined,
) => {
  locationData: FeatureCollection<LineString, GeoJsonProperties>;
  placeData: FeatureCollection<Point, GeoJsonProperties>;
  insightsData: insightsData;
  startingViewport: {
    zoom: number;
    pitch: number;
    width: number;
    height: number;
    latitude: number;
    longitude: number;
  };
};

const useActivityData: useMapData = (data) => {
  const mapData = useMemo(() => {
    // build location data
    const lineDataFeatureCollection: FeatureCollection<GeoJSON.LineString> = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            color: '#F7455D',
          },
          geometry: {
            type: 'LineString',
            coordinates: data
              ?.map((item) => {
                if (item.eventType !== 'location') {
                  return null;
                }

                return item?.userLocation?.coordinates;
              })
              .filter((item) => item !== null) as [number, number][],
          },
        },
      ],
    };

    // detect user's timezone
    const timezone = moment.parseZone(data?.[0]?.timestamp).format('Z');

    // build place data
    const placeFeatureCollection: FeatureCollection<GeoJSON.Point> = {
      type: 'FeatureCollection',
      features: _.uniqBy(
        data
          ?.map((item) => {
            if (!item.userLocation) return null;

            if (item.eventType !== 'user.entered_place') {
              return null;
            }

            return {
              type: 'Feature',
              properties: {
                id: item.eventId,
                place: item?.place,
                timestamp: moment(item?.timestamp).utcOffset(timezone).format(),
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                // @ts-expect-error  confidence is returned from raw data; s3 converts raw string to object;
                confidence: item?.confidence || item?.raw?.event?.confidence,
              },
              geometry: {
                type: 'Point',
                coordinates: item?.place?.location?.coordinates,
              },
            };
          })
          .filter((item) => item !== null) as Feature<GeoJSON.Point>[],
        'properties.place.name',
      ),
    };

    // build insights data
    const homeCoordinates = data?.[data.length - 1]?.insights?.locations?.find(
      (item) => item?.type === 'home',
    );
    const workCoordinates = data?.[data.length - 1]?.insights?.locations?.find(
      (item) => item?.type === 'office',
    );

    const insightsData: insightsData = {
      home: {
        type: 'FeatureCollection',
        features: !homeCoordinates?.location?.coordinates
          ? []
          : [
              {
                type: 'Feature',
                properties: {
                  id: 'home',
                },
                geometry: {
                  type: 'Point',
                  coordinates: homeCoordinates.location?.coordinates,
                },
              } as Feature<GeoJSON.Point>,
            ],
      },
      work: {
        type: 'FeatureCollection',
        features: !workCoordinates?.location?.coordinates
          ? []
          : [
              {
                type: 'Feature',
                properties: {
                  id: 'work',
                },
                geometry: {
                  type: 'Point',
                  coordinates: workCoordinates.location?.coordinates,
                },
              } as Feature<GeoJSON.Point>,
            ],
      },
    };

    // calculate the bounds of the startingViewport
    const bounds = calculateBoundingBox(
      [
        ...(lineDataFeatureCollection?.features?.[0]?.geometry?.coordinates || []),
        ...placeFeatureCollection.features.map((feature) => feature.geometry.coordinates),
        insightsData?.home?.features?.[0]?.geometry?.coordinates,
        insightsData?.work?.features?.[0]?.geometry?.coordinates,
      ].filter((item) => item !== undefined) as [number, number][],
    );

    const startingViewport = new WebMercatorViewport({
      // view width - Sidebar width
      width: window.innerWidth - 364,
      height: 700,
    }).fitBounds(bounds, { padding: 100 });

    return {
      locationData: lineDataFeatureCollection,
      placeData: placeFeatureCollection,
      insightsData,
      startingViewport: {
        ...startingViewport,
        zoom: Math.min(startingViewport.zoom, 17),
      },
    };
  }, [data]);

  return mapData;
};

export default useActivityData;
