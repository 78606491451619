import { useMemo } from 'react';
import { FeatureCollection, GeoJsonProperties, Point, Feature } from 'geojson';
import { CreateRadarEventsInput } from 'API';
import { WebMercatorViewport } from 'react-map-gl';
import calculateBoundingBox from '../utils/calculateBoundingBox';

type useHeatData = (
  data: CreateRadarEventsInput[] | undefined,
) => {
  heatData: FeatureCollection<Point, GeoJsonProperties>;
  startingViewport: {
    zoom: number;
    pitch: number;
    width: number;
    height: number;
    latitude: number;
    longitude: number;
  };
};

const useHeatData: useHeatData = (data) => {
  const mapData = useMemo(() => {
    const features = data
      ?.map((item) => {
        if (!item.userLocation) return null;

        return {
          type: 'Feature',
          properties: {
            id: item.eventId,
          },
          geometry: {
            type: 'Point',
            coordinates: item.userLocation.coordinates,
          },
        };
      })
      .filter((item) => item !== null) as Feature<GeoJSON.Point>[];

    const heatData: FeatureCollection<GeoJSON.Point> = {
      type: 'FeatureCollection',
      features,
    };

    // calculate the bounds of the startingViewport
    const bounds = calculateBoundingBox(
      [...(heatData?.features?.map((feature) => feature.geometry.coordinates) || [])].filter(
        (item) => item !== undefined,
      ) as [number, number][],
    );

    const startingViewport = new WebMercatorViewport({
      // view width - Sidebar width
      width: window.innerWidth - 364,
      height: 300,
    }).fitBounds(bounds, { padding: 35 });

    return {
      heatData,
      startingViewport: {
        ...startingViewport,
        zoom: Math.min(startingViewport.zoom, 17),
      },
    };
  }, [data]);

  return mapData;
};

export default useHeatData;
