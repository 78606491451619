import styled from 'styled-components';
import { Button } from 'elements';

export const SubHeaderBar = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CreateButton = styled(Button)`
  margin: 0;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;
