import { API, graphqlOperation } from 'aws-amplify';
import { queryDailyQuestionsByUser } from 'graphql/queries';
import { QueryDailyQuestionsByUserQuery, CreateDailyQuestionInput } from 'API';

type fetchDailyQuestions = (
  key: string,
  { user }: { user: string | undefined },
) => Promise<CreateDailyQuestionInput[]>;

const fetchDailyQuestions: fetchDailyQuestions = async (key, { user }) => {
  // fetch users by group
  const { data } = (await API.graphql(
    graphqlOperation(queryDailyQuestionsByUser, {
      user,
      first: 1000,
    }),
  )) as {
    data: QueryDailyQuestionsByUserQuery;
  };

  // return empty array if invalid return
  if (!data.queryDailyQuestionsByUser?.items) return [];

  // original array
  const usersArray: (CreateDailyQuestionInput | null)[] = data.queryDailyQuestionsByUser.items;

  // filter out null values
  const filteredArray: CreateDailyQuestionInput[] = usersArray.filter(
    (item: CreateDailyQuestionInput | null): item is CreateDailyQuestionInput => item !== null,
  );

  return filteredArray;
};

export default fetchDailyQuestions;
