import React from 'react';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';

type TimestampPicker = {
  value?: moment.Moment;
  onChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void;
  id: string;
  name: string;
  label: string;
};

const TimestampPicker = ({ value, onChange, id, name, label }: TimestampPicker) => {
  return (
    <KeyboardDateTimePicker
      showTodayButton
      label={label}
      id={id}
      name={name}
      aria-labelledby={name}
      format="DD/MM/YYYY, h:mm a"
      margin="normal"
      disableFuture
      value={value}
      onChange={onChange}
      fullWidth
      error={!value || !moment(value).isValid()}
      helperText={null}
    />
  );
};

export default TimestampPicker;
