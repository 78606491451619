import { API, graphqlOperation } from 'aws-amplify';
import { queryOrganization } from 'graphql/queries';
import { QueryOrganizationQuery } from 'API';

type fetchOrganizationSettings = (
  key: string,
  organization: string,
) => Promise<{ alert_time_hours: number | null; push_notify: number | null; id: string }>;

const fetchOrganizationSettings: fetchOrganizationSettings = async (key, organization) => {
  // fetch users by group
  const { data } = (await API.graphql(
    graphqlOperation(queryOrganization, {
      organization,
      first: 1000,
    }),
  )) as {
    data: QueryOrganizationQuery;
  };

  // return empty object if invalid return
  if (!data.queryOrganization?.items?.[0]) return { alert_time_hours: 0, id: '', push_notify: 0 };

  // get settings
  const { alert_time_hours, id, push_notify } = data.queryOrganization.items[0];

  // return organization settings
  return { alert_time_hours, id, push_notify };
};

export default fetchOrganizationSettings;
