import React, { useState, useEffect } from 'react';
import MapGL, { Source, Layer } from 'react-map-gl';
import { SubHeader, LoadingIcon, ErrorHandler } from 'elements';
import { useHistory } from 'react-router-dom';
import { Center } from 'styles/utils';
import { useUserProfile } from 'components/user/__hooks__';
import { useDateRange } from 'context/date-range';
import { Feature, Point, GeoJsonProperties } from 'geojson';
import theme from 'styles/theme';
import useActivityData from '../__hooks__/useActivityData/useActivityData';
import Pins from '../Pins/Pins';
import Popup from '../Popup/Popup';
import MapControls from '../MapControls/MapControls';

const ActivityMap = () => {
  const history = useHistory();
  const [dateRange] = useDateRange();
  const [popupInfo, setPopupInfo] = useState<Feature<Point, GeoJsonProperties> | null>(null);
  const {
    user,
    userData: { isLoading, isError, data, error },
  } = useUserProfile({ dateRange });

  const { locationData, placeData, insightsData, startingViewport } = useActivityData(data);

  const [viewport, setViewport] = useState({
    width: 40,
    height: -100,
    latitude: 0,
    longitude: 0,
    zoom: 7,
  });

  // set viewport to first point when map is loaded
  useEffect(() => {
    setViewport(startingViewport);
  }, [startingViewport, setViewport]);

  if (isLoading) {
    return <LoadingIcon />;
  }

  if (isError) {
    return <ErrorHandler error={error} message="Failed to pull user's location data" />;
  }

  if (!user) {
    history.push('/');
    return null;
  }

  if (data === undefined || data.length === 0) {
    return (
      <Center>
        <SubHeader>No location data exists for this user in the provided range.</SubHeader>
      </Center>
    );
  }

  const MAX_ZOOM_LEVEL = 17;

  return (
    <div onBlur={() => setPopupInfo(null)}>
      <MapGL
        mapStyle="mapbox://styles/mapbox/streets-v11"
        width="100%"
        height={700}
        latitude={viewport.latitude}
        longitude={viewport.longitude}
        zoom={viewport.zoom}
        maxZoom={MAX_ZOOM_LEVEL}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        onViewportChange={(nextViewport) => {
          setViewport(nextViewport);
        }}
      >
        <MapControls />
        <Popup popupInfo={popupInfo} setPopupInfo={setPopupInfo} />
        <Pins onClick={setPopupInfo} data={placeData} type="place" />
        <Pins onClick={setPopupInfo} data={insightsData.home} type="home" />
        <Pins onClick={setPopupInfo} data={insightsData.work} type="work" />
        <Source type="geojson" data={locationData}>
          <Layer
            type="line"
            source="route"
            layout={{
              'line-join': 'round',
              'line-cap': 'round',
            }}
            paint={{ 'line-width': 3, 'line-color': theme.green1, 'line-opacity': 1 }}
          />
        </Source>
      </MapGL>
    </div>
  );
};

export default ActivityMap;
