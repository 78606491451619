import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useUsersList } from 'context/users';
import { DailyQuestionsForm } from 'types';
import fetchDailyQuestions from './utils/fetchDailyQuestions';
import submitDailyQuestions from './utils/submitDailyQuestions';

const useDailyQuestions = () => {
  // get the current user
  const { data: userList } = useUsersList();
  const { id: currentUserID } = useParams();
  const currentUser = useMemo(() => {
    if (!userList) return undefined;
    return userList.find(({ user }) => user === currentUserID);
  }, [userList, currentUserID]);

  const [submit, submitMutation] = useMutation(submitDailyQuestions);
  const listQuery = useQuery(
    ['fetchDailyQuestions', { user: currentUser?.user }],
    fetchDailyQuestions,
  );

  // pass form data and currentUser object to GQL
  const onSubmit = ({ formData }: { formData: DailyQuestionsForm }) =>
    submit({ formData, currentUser });

  return {
    list: listQuery,
    submit: {
      submitDailyQuestions: onSubmit,
      ...submitMutation,
    },
  };
};

export default useDailyQuestions;
