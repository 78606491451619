import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20%;
  padding: 16px;
  border-radius: ${(props) => props.theme.borderRadius};
  transition: background-color 0.25s, box-shadow 0.25s;

  &:hover {
    background-color: white;
    box-shadow: ${(props) => props.theme.cardBoxShadow};
  }
`;
