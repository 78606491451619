import styled from 'styled-components';
import { ScaleControl, NavigationControl, FullscreenControl } from 'react-map-gl';

export const Scale = styled(ScaleControl)`
  position: absolute;
  top: 0px;
  left: 0px;
`;

export const Navigation = styled(NavigationControl)`
  position: absolute;
  top: 80px;
  left: 16px;
  display: grid;
  grid-gap: 10px;
  background-color: white;
  padding: 8px;
  border-radius: ${(props) => props.theme.borderRadius} !important;
  box-shadow: ${(props) => props.theme.centeredBoxShadow} !important;

  button {
    border: none !important;
    border-radius: ${(props) => props.theme.borderRadius} !important;
  }
`;

export const Fullscreen = styled(FullscreenControl)`
  position: absolute;
  top: 18px;
  left: 16px;
  background-color: white;
  padding: 8px;
  border-radius: ${(props) => props.theme.borderRadius} !important;
  box-shadow: ${(props) => props.theme.centeredBoxShadow} !important;

  button {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;
