import styled from 'styled-components';
import { Body } from '../Typography/Typography';

export const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
`;

export const Labels = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const Label = styled(Body)`
  margin: 0;
`;

export const LeftLabels = styled.div`
  text-align: left;
  padding-right: 8px;
`;

export const MiddleLabels = styled.div`
  text-align: center;
`;

export const RightLabels = styled.div`
  text-align: right;
  padding-left: 8px;
`;
