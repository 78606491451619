import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import ky from 'ky';
import { queryRadarEventsByOrganizationTimestampRange } from 'graphql-custom/queries';
import { QueryRadarEventsByOrganizationTimestampRangeQuery, CreateRadarEventsInput } from 'API';

type fetchOrganizationLocationDataProps = {
  dateRange: {
    start: moment.Moment;
    end: moment.Moment;
  };
  organization: string;
};

const fetchOrganizationLocationData = async (
  key: string,
  { dateRange, organization }: fetchOrganizationLocationDataProps,
) => {
  // if the logged-in user does not belng to an organization
  if (!organization) return { locationData: [], activityData: [], organization };

  // if date is invalid, return empty
  if (
    moment(dateRange.start).isBefore(moment().subtract(30, 'days').startOf('day')) ||
    moment(dateRange.start).isAfter(moment()) ||
    moment(dateRange.end).isBefore(
      moment(dateRange.start) || moment().subtract(30, 'days').startOf('day'),
    ) ||
    moment(dateRange.end).isAfter(moment())
  ) {
    return { locationData: [], activityData: [], organization };
  }

  // format dates in correct format and adjust for date range to be inclusive
  const startDate = moment(dateRange.start).startOf('day').utc().format();
  const endDate = moment(dateRange.end).endOf('day').utc().format();

  // fetch s3 url for users Radar events
  const { data } = (await API.graphql(
    graphqlOperation(queryRadarEventsByOrganizationTimestampRange, {
      organization,
      startDate,
      endDate,
    }),
  )) as {
    data: QueryRadarEventsByOrganizationTimestampRangeQuery;
  };

  // get s3 url
  const url = data.queryRadarEventsByOrganizationTimestampRange?.url;

  // throw if no url returned
  if (!url) throw new Error('No S3 URL');

  // get Radar events from s3 url
  const result = (await ky.get(url).json()) as CreateRadarEventsInput[];

  return {
    locationData: result.filter((item) => item.eventType === 'location'),
    activityData: result.filter((item) => item.eventType !== 'location'),
    organization,
  };
};

export default fetchOrganizationLocationData;
