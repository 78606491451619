import React from 'react';
import { useHistory } from 'react-router-dom';
import { StyledButton } from './Button.styled';

export type ButtonProps = {
  ['data-testid']?: string;
  children: string | string[];
  to?: string;
  onClick?: () => void;
  type?: 'filled' | 'text';
  disabled?: boolean;
  loading?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  className?: string;
};

const Button = ({
  'data-testid': dataTestId,
  children,
  to,
  onClick,
  type = 'filled',
  disabled = false,
  loading = false,
  htmlType = 'button',
  className,
}: ButtonProps) => {
  const history = useHistory();

  const onButtonClick = () => {
    // if disabled, don't do anything (used for integration tests)
    if (disabled || loading) {
      return;
    }

    // call the user provided function (if exists)
    if (onClick) {
      onClick();
    }

    // back button functionality
    if (to === 'previous') {
      history.goBack();
      return;
    }

    // push to history (if 'to' prop is provided)
    if (to) {
      history.push(to);
    }
  };

  return (
    <StyledButton
      data-testid={dataTestId}
      className={className}
      type={htmlType}
      myType={type}
      onClick={onButtonClick}
      disabled={disabled || loading}
      loading={loading ? 'true' : 'false'}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
