import React from 'react';
import { Header, DateRangePicker } from 'elements';
import { OrganizationExportButtons } from 'components/organization';
import { CsvHeader } from './OrganizationExport.styled';

const OrganizationExport = () => {
  return (
    <>
      <CsvHeader>
        <Header>Export</Header>
        <DateRangePicker />
      </CsvHeader>
      <OrganizationExportButtons />
    </>
  );
};

export default OrganizationExport;
