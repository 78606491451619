export default {
  borderRadius: '8px',
  centeredBoxShadow: '0 0 14px rgba(195, 204, 238, 0.40)',
  cardBoxShadow: '0 4px 14px rgba(195, 204, 238, 0.40)',

  // colors
  primary1: '#4bcbf3',
  primary2: '#0098c6',
  primary3: '#094074',
  primary4: '#0b2545',

  // addison green
  green1: '#49C0AD',
  green2: '#009F93',
  green3: '#016E75',
  green4: '#03312E',

  purple1: '#D2B2DD',
  purple2: '#BB84D0',
  purple3: '#9944BB',
  purple4: '#441155',

  // addison steel
  steel1: '#EEF0F6',
  steel2: '#D2D6E8',
  steel3: '#8DA9C4',
  steel4: '#3A4456',

  // danger
  danger1: '#f24b4b',
  danger2: '#d14d4d',

  textLight: '#dae3eb',
  textDark: '#0b2545',
  headerFont: 'Roboto Condensed, -apple-system, BlinkMacSystemFont, Segoe UI, sans-serif',
  bodyFont: 'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, sans-serif',
};
