import styled from 'styled-components';
import { Header, Body } from 'elements';

export const DropdownContainer = styled.div`
  position: absolute;
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: ${(props) => props.theme.cardBoxShadow};
  overflow: hidden;
  top: 100%;
  width: 100%;
  margin-top: 8px;
  background-color: white;
  z-index: 999;
`;

export const DropdownItem = styled.div`
  padding: 16px;
  width: 100%;
  background-color: white;
  cursor: pointer;

  p {
    margin: 0;
  }

  &:hover {
    background-color: ${(props) => props.theme.primary2};
    color: white;
  }
`;

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
`;

export const UserCard = styled.div<{ isDropdownOpen: boolean }>`
  border-radius: ${(props) => props.theme.borderRadius};
  display: grid;
  align-items: center;
  grid-template-columns: 70px 1fr 25px;
  padding: 16px;
  width: 100%;
  transition: background-color 0.25s, box-shadow 0.25s;
  cursor: pointer;

  /* keep UserCard highlighted when dropdown is open */
  ${(props) =>
    props.isDropdownOpen
      ? `
    background-color: white;
    box-shadow: ${props.theme.cardBoxShadow};
  `
      : ``}

  &:hover {
    background-color: white;
    box-shadow: ${(props) => props.theme.cardBoxShadow};
  }
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Username = styled(Header)`
  margin: 0;
`;

export const Group = styled(Body)`
  opacity: 0.5;
  margin: 0;
`;
