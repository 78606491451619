import React from 'react';
import { Snackbar } from '@material-ui/core';
import MaterialAlert from '@material-ui/lab/Alert';

type AlertProps = {
  open: boolean;
  setOpen: Function;
  message: string;
  severity: 'success' | 'error';
};

const Alert = ({ open, setOpen, message, severity }: AlertProps) => {
  return (
    <Snackbar
      open={open}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={6000}
    >
      <MaterialAlert elevation={6} severity={severity} variant="filled">
        {message}
      </MaterialAlert>
    </Snackbar>
  );
};

export default Alert;
