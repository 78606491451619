import React from 'react';
import ReactDOM from 'react-dom';
import theme from 'styles/theme';
import materialTheme from 'styles/material-theme';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Amplify from 'aws-amplify';
import { ReactQueryConfigProvider } from 'react-query';
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { UsersProvider } from 'context/users';
import { OrganizationProvider } from 'context/organization';
import { AlertProvider } from 'context/alert';
import * as Sentry from '@sentry/browser';
import MomentUtils from '@date-io/moment';
import { isFirefox, isChrome, isEdgeChromium, browserName } from 'react-device-detect';
import { App } from './components/display';
import 'styles/globalStyles.css';
import 'styles/fonts.css';
import 'styles/amplifyOverrides.css';
import 'mapbox-gl/dist/mapbox-gl.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
// @ts-expect-error
import awsconfig from './aws-exports';

// react-query config
const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  },
};

// initialize Sentry
Sentry.init({ dsn: 'https://bbacf7ff9042493e9053d4477f8ed0cb@o364041.ingest.sentry.io/5270454' });

// initialize Amplify
Amplify.configure({
  ...awsconfig,
  aws_appsync_graphqlEndpoint:
    'https://sv246smdpjcrnhf53rj6zda65i.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
});

// block app for unsupported browsers
if (!isFirefox && !isChrome && !isEdgeChromium) {
  ReactDOM.render(
    <div style={{ marginLeft: '18px' }}>
      <h1>Unsupported browser: {browserName}</h1>
      <p>
        Please install <a href="https://www.google.com/chrome/">Google Chrome</a>,{' '}
        <a href="https://www.mozilla.org/en-US/firefox/">Firefox</a>, or the new{' '}
        <a href="https://www.microsoft.com/en-us/edge">Chromium-based Edge</a> to continue.
      </p>
    </div>,
    document.getElementById('root'),
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <ReactQueryConfigProvider config={queryConfig}>
        <BrowserRouter>
          <MaterialThemeProvider theme={materialTheme}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
              <ThemeProvider theme={theme}>
                <OrganizationProvider>
                  <UsersProvider>
                    <AlertProvider>
                      <App />
                    </AlertProvider>
                  </UsersProvider>
                </OrganizationProvider>
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </MaterialThemeProvider>
        </BrowserRouter>
      </ReactQueryConfigProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
