import React from 'react';
import { Logo } from 'elements';
import {
  AiOutlineHome as HomeIcon,
  AiOutlineUser as UsersIcon,
  AiOutlineSetting as SettingsIcon,
  AiOutlineApartment as OrgnizationIcon,
} from 'react-icons/ai';
import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';
import { useCognitoUser } from 'hooks';
import { Container, Content, NavlinkLabel, Navlink, NavlinkContainer } from './Sidebar.styled';

// takes a route and returns the icon for that route
const getIcon = ({ to }: { to: string }) => {
  const routeToIcon: { [index: string]: IconType } = {
    '/': HomeIcon,
    '/users': UsersIcon,
    '/settings': SettingsIcon,
    '/organization': OrgnizationIcon,
  };

  return routeToIcon[to];
};

const SidebarNavButton = ({ children, to }: { children: string; to: string }) => {
  const { pathname: currentRoute } = useLocation();
  const Icon = getIcon({ to });

  return (
    <NavlinkContainer to={to} currentRoute={currentRoute}>
      <Navlink to={to}>
        <Icon size="1.3em" color="#b1b1b1" />
        <NavlinkLabel>{children}</NavlinkLabel>
      </Navlink>
    </NavlinkContainer>
  );
};

const Sidebar = () => {
  const { data: cognitoUser } = useCognitoUser();

  return (
    <Container>
      <Content>
        <Logo>Wallpass</Logo>
        <SidebarNavButton to="/">Home</SidebarNavButton>
        <SidebarNavButton to="/users">Users</SidebarNavButton>
        <SidebarNavButton to="/organization">Organization</SidebarNavButton>
        {cognitoUser?.isAdmin && <SidebarNavButton to="/settings">Settings</SidebarNavButton>}
      </Content>
    </Container>
  );
};

export default Sidebar;
