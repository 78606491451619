import { API, graphqlOperation } from 'aws-amplify';
import { deleteVerifiedNativeUsers } from 'graphql/mutations';
import { DeleteVerifiedNativeUsersInput, DeleteVerifiedNativeUsersMutation } from 'API';

const deleteUser = async ({ input }: { input: DeleteVerifiedNativeUsersInput }) => {
  const { data } = (await API.graphql(
    graphqlOperation(deleteVerifiedNativeUsers, {
      input: {
        ...input,
      },
    }),
  )) as {
    data: DeleteVerifiedNativeUsersMutation;
  };

  return data;
};

export default deleteUser;
