import styled from 'styled-components';
import { Link as RouteLink } from 'react-router-dom';

export const Link = styled(RouteLink)`
  text-decoration: none;
  color: inherit;
`;

export const UserEntryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;

  &:hover {
    background-color: ${(props) => props.theme.primary2};
    color: white;
  }
`;
