import React, { useState } from 'react';
import Alert from 'elements/Alert/Alert';

const useAlert = () => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState<'success' | 'error'>('success');

  const successAlert = ({ message }: { message: string }) => {
    setAlertMessage(message);
    setSeverity('success');
    setOpen(true);
  };

  const errorAlert = ({ message }: { message: string }) => {
    setAlertMessage(message);
    setSeverity('error');
    setOpen(true);
  };

  return {
    Alert: <Alert message={alertMessage} severity={severity} open={open} setOpen={setOpen} />,
    successAlert,
    errorAlert,
  };
};

export default useAlert;
