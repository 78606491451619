/* eslint-disable prefer-destructuring */
import { Coordinates } from 'viewport-mercator-project';

type calculateBoundingBox = (arr: [number, number][] | undefined) => [Coordinates, Coordinates];

// note [0] elements are lng [1] are lat
const calculateBoundingBox: calculateBoundingBox = (arr) => {
  if (!arr || arr.length === 0)
    return [
      [0, 0],
      [0, 0],
    ] as [Coordinates, Coordinates];

  const ne = [arr[0][0], arr[0][1]];
  const sw = [arr[0][0], arr[0][1]];

  arr.forEach((item) => {
    if (ne[0] < item[0]) ne[0] = item[0];
    if (ne[1] < item[1]) ne[1] = item[1];
    if (sw[0] > item[0]) sw[0] = item[0];
    if (sw[1] > item[1]) sw[1] = item[1];
  });

  return [ne, sw] as [Coordinates, Coordinates];
};

export default calculateBoundingBox;
