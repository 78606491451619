import styled from 'styled-components';

export const VerifiedUserListContainer = styled.div`
  display: grid;
  grid-gap: 32px;
`;

export const UsersListGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;

  @media (max-width: 1800px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`;
