import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { LoadingIcon, ErrorHandler } from 'elements';
import { Home, UserList, UserProfile, Settings, Organization } from 'pages';
import { useCognitoUser } from 'hooks';
import { useUsersList } from 'context/users';
import { DateRangeProvider } from 'context/date-range';
import { useOrganization } from 'context/organization';
import * as Sentry from '@sentry/browser';

const DataDisplay = () => {
  const { data: cognitoUser } = useCognitoUser();
  const { isLoading, isError, error } = useUsersList();
  const [, setOrganization] = useOrganization();

  useEffect(() => {
    Sentry.setUser({
      username: cognitoUser?.username,
      group: cognitoUser?.groups.filter((data: string) => data !== 'Admin')[0],
      admin: cognitoUser?.isAdmin,
    });

    setOrganization(cognitoUser?.groups.filter((data: string) => data !== 'Admin')[0]);
  }, [cognitoUser, setOrganization]);

  if (isLoading) {
    return <LoadingIcon />;
  }

  if (isError) {
    return <ErrorHandler error={error} message="Failed to fetch users list" />;
  }

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/users">
        <UserList />
      </Route>
      <Route exact path="/organization">
        <DateRangeProvider>
          <Organization />
        </DateRangeProvider>
      </Route>
      <Route path="/user/:id">
        <DateRangeProvider>
          <UserProfile />
        </DateRangeProvider>
      </Route>
      {cognitoUser?.isAdmin && (
        <Route path="/settings">
          <Settings />
        </Route>
      )}
      <Route>
        <Home />
      </Route>
    </Switch>
  );
};

export default DataDisplay;
