import React, { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { useAlert } from 'context/alert';
import { Header, SubHeader, Body } from '../Typography/Typography';

type ErrorHandlerProps = {
  error: any;
  message: string;
};

const ErrorHandler = ({ error, message }: ErrorHandlerProps) => {
  const gqlErrors: Error[] = error?.errors;
  const { errorAlert } = useAlert();

  if (gqlErrors) {
    gqlErrors.forEach((e) => Sentry.captureException(e));
  } else if (error === 'No current user') {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  } else {
    Sentry.captureException(error);
  }

  // display error alert
  useEffect(() => {
    errorAlert({ message });
  }, [errorAlert, message]);

  return (
    <div>
      <Header>An error has occured.</Header>
      <SubHeader>Our team has been notified.</SubHeader>
      <Body>{JSON.stringify(error)}</Body>
    </div>
  );
};

export default ErrorHandler;
