import React from 'react';
import { CreateVerifiedNativeUsersInput } from 'API';
import moment from 'moment';
import DeleteUser from '../DeleteUser/DeleteUser';
import { Data, Timestamp, UserId, StartColumn, Disabled, OptOutHeader } from './UserCard.styled';

type UserCardProps = {
  user: CreateVerifiedNativeUsersInput;
  handleDeleteUser: Function;
};

const UserCard = ({ user, handleDeleteUser }: UserCardProps) => {
  return (
    <>
      <Data data-testid="user-row">
        {user.active === 'no' && (
          <Disabled>
            <OptOutHeader>Opted-out</OptOutHeader>
          </Disabled>
        )}
        <StartColumn>
          <div>
            <UserId>{user.user}</UserId>
            <Timestamp secondary>Added {moment(user.activeDate).format('LLLL')}</Timestamp>
          </div>
        </StartColumn>
        <DeleteUser user={user} handleDeleteUser={handleDeleteUser} />
      </Data>
    </>
  );
};

export default UserCard;
