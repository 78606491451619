import { API, graphqlOperation } from 'aws-amplify';
import { updateVerifiedNativeUsers } from 'graphql/mutations';
import { UpdateVerifiedNativeUsersMutation, UpdateVerifiedNativeUsersInput } from 'API';

type setActiveStatus = (props: {
  formData: UpdateVerifiedNativeUsersInput;
}) => Promise<UpdateVerifiedNativeUsersMutation>;

const setActiveStatus: setActiveStatus = async ({ formData }) => {
  // fetch users by group
  const { data } = (await API.graphql(
    graphqlOperation(updateVerifiedNativeUsers, {
      input: {
        ...formData,
      },
    }),
  )) as {
    data: UpdateVerifiedNativeUsersMutation;
  };

  return data;
};

export default setActiveStatus;
