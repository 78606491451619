import { Auth } from 'aws-amplify';
import { useQuery } from 'react-query';

const getCognitoInfo = async () => {
  // get group name from cognito session
  const cognitoInfo = await Auth.currentSession();

  const groups: string[] = cognitoInfo.getIdToken().payload['cognito:groups'];
  const isAdmin: boolean = groups.includes('Admin');
  const username: string = cognitoInfo.getIdToken().payload.name;

  return { groups, username, isAdmin };
};

const useCognitoUser = () => {
  const query = useQuery('getCognitoInfo', getCognitoInfo);

  return query;
};

export default useCognitoUser;
