import { API, graphqlOperation } from 'aws-amplify';
import ky from 'ky';
import { queryRadarEventsByUserTimestampRange } from 'graphql-custom/queries';
import { QueryRadarEventsByUserTimestampRangeQuery, CreateRadarEventsInput } from 'API';
import moment from 'moment';

type fetchUserData = (
  key: string,
  user: string | undefined,
  dateRange: {
    start: moment.Moment;
    end: moment.Moment;
  },
) => Promise<CreateRadarEventsInput[]>;

const fetchUserData: fetchUserData = async (key, user, dateRange) => {
  // if user doesn't exist for the current logged in user
  if (!user) return [];

  // if date is invalid, return empty
  if (
    moment(dateRange.start).isBefore(moment().subtract(30, 'days').startOf('day')) ||
    moment(dateRange.start).isAfter(moment()) ||
    moment(dateRange.end).isBefore(
      moment(dateRange.start) || moment().subtract(30, 'days').startOf('day'),
    ) ||
    moment(dateRange.end).isAfter(moment())
  ) {
    return [];
  }

  // format dates in correct format and adjust for date range to be inclusive
  const startDate = moment(dateRange.start).startOf('day').utc().format();
  const endDate = moment(dateRange.end).endOf('day').utc().format();

  // fetch users Radar events
  const { data } = (await API.graphql(
    graphqlOperation(queryRadarEventsByUserTimestampRange, {
      user,
      startDate,
      endDate,
    }),
  )) as {
    data: QueryRadarEventsByUserTimestampRangeQuery;
  };

  // get s3 url
  const url = data.queryRadarEventsByUserTimestampRange?.url;

  // throw if no url returned
  if (!url) throw new Error('No S3 URL');

  // get Radar events from s3 url
  const result = (await ky.get(url).json()) as CreateRadarEventsInput[];

  // filter out null values
  const filteredArray: CreateRadarEventsInput[] = result.filter(
    (item: CreateRadarEventsInput | null): item is CreateRadarEventsInput => item !== null,
  );

  return filteredArray;
};

export default fetchUserData;
