import React, { useState } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { FaUserCircle } from 'react-icons/fa';
import theme from 'styles/theme';
import { Body } from 'elements';
import { Auth } from 'aws-amplify';
import { useCognitoUser } from 'hooks';
import { useOrganization } from 'context/organization';
import { startCase } from 'lodash';
import SwitchOrganizationDialog from '../SwitchOrganizationDialog/SwitchOrganizationDialog';
import {
  Container,
  UserCard,
  DropdownContainer,
  DropdownItem,
  Information,
  Username,
  Group,
} from './UserInformation.styled';

type DropdownMenuProps = {
  isDropdownOpen: boolean;
  onClick: Function;
  setIsDialogOpen: Function;
  setIsDropdownOpen: Function;
};

const DropdownMenu = ({
  isDropdownOpen,
  onClick,
  setIsDialogOpen,
  setIsDropdownOpen,
}: DropdownMenuProps) => {
  if (!isDropdownOpen) return null;

  return (
    <DropdownContainer onMouseLeave={() => setIsDropdownOpen(false)}>
      <DropdownItem
        onClick={() => {
          setIsDialogOpen(true);
          setIsDropdownOpen(false);
        }}
      >
        <Body>Switch Organization</Body>
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          onClick();
          setIsDropdownOpen(false);
        }}
      >
        <Body>Log Out</Body>
      </DropdownItem>
    </DropdownContainer>
  );
};

const UserInformation = () => {
  const { isSuccess, data } = useCognitoUser();
  const [organization] = useOrganization();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Container>
      <SwitchOrganizationDialog open={isDialogOpen} setOpen={setIsDialogOpen} />
      <UserCard isDropdownOpen={isDropdownOpen} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <FaUserCircle size="3em" color={theme.primary1} />
        <Information>
          <Username>{isSuccess ? data?.username : '-'}</Username>
          <Group>{isSuccess ? startCase(organization) : '-'}</Group>
        </Information>
        <RiArrowDropDownLine size="2em" />
      </UserCard>
      <DropdownMenu
        isDropdownOpen={isDropdownOpen}
        setIsDialogOpen={setIsDialogOpen}
        onClick={() => {
          Auth.signOut();
          // eslint-disable-next-line no-restricted-globals
          location.reload();
          // eslint-disable-next-line no-restricted-globals
          location.replace('/');
        }}
        setIsDropdownOpen={setIsDropdownOpen}
      />
    </Container>
  );
};

export default UserInformation;
