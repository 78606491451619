import React from 'react';
import { AiOutlineBell } from 'react-icons/ai';
import { Container } from './NotificationBell.styled';

const NotificationBell = () => {
  return (
    <Container>
      <AiOutlineBell fontSize="2em" />
    </Container>
  );
};

export default NotificationBell;
