import { API, graphqlOperation, Auth } from 'aws-amplify';
import { createVerifiedNativeUsers } from 'graphql/mutations';
import { CreateVerifiedNativeUsersInput, CreateVerifiedNativeUsersMutation, YesNo } from 'API';
import moment from 'moment';

type createUserProps = (props: {
  user: string;
  organization: string;
}) => Promise<CreateVerifiedNativeUsersMutation>;

const createUser: createUserProps = async ({ user, organization }) => {
  // fetch users by group
  // get group name from cognito session
  const activatedBy = await Auth.currentSession().then(
    (result) => result.getIdToken().payload.name,
  );

  const activeDate = moment().format();

  const input: CreateVerifiedNativeUsersInput = {
    activatedBy,
    organization,
    user: user.toLowerCase(),
    active: 'yes' as YesNo,
    activeDate,
  };

  const { data } = (await API.graphql(
    graphqlOperation(createVerifiedNativeUsers, {
      input: {
        ...input,
      },
    }),
  )) as {
    data: CreateVerifiedNativeUsersMutation;
  };

  return data;
};

export default createUser;
