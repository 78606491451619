import React, { useEffect, useState } from 'react';
import { Searchbox } from 'elements';
import { CreateVerifiedNativeUsersInput } from 'API';

type useSearchbox = (
  array: CreateVerifiedNativeUsersInput[] | undefined,
) => [React.ReactElement | null, CreateVerifiedNativeUsersInput[] | undefined];

const useSearchbox: useSearchbox = (array) => {
  const [searchboxValue, setSearchboxValue] = useState('');
  const [filteredArray, setFilteredArray] = useState(array);

  useEffect(() => {
    setFilteredArray(
      array?.filter(({ user }) => {
        return user.includes(searchboxValue);
      }),
    );
  }, [searchboxValue, setFilteredArray, array]);

  return [
    <Searchbox
      value={searchboxValue}
      onChange={(event) => {
        setSearchboxValue(event.target.value);
      }}
    />,
    filteredArray,
  ];
};

export default useSearchbox;
