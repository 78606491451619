type dictionary = {
  [key: string]: string;
};

const appleDeviceTypes: dictionary = {
  default: '',
  'iPhone1,1': 'iPhone',
  'iPhone1,2': 'iPhone 3G',
  'iPhone2,1': 'iPhone 3GS',
  'iPhone3,1': 'iPhone 4',
  'iPhone3,2': 'iPhone 4 GSM Rev A',
  'iPhone3,3': 'iPhone 4 CDMA',
  'iPhone4,1': 'iPhone 4S',
  'iPhone5,1': 'iPhone 5 (GSM)',
  'iPhone5,2': 'iPhone 5 (GSM+CDMA)',
  'iPhone5,3': 'iPhone 5C (GSM)',
  'iPhone5,4': 'iPhone 5C (Global)',
  'iPhone6,1': 'iPhone 5S (GSM)',
  'iPhone6,2': 'iPhone 5S (Global)',
  'iPhone7,1': 'iPhone 6 Plus',
  'iPhone7,2': 'iPhone 6',
  'iPhone8,1': 'iPhone 6s',
  'iPhone8,2': 'iPhone 6s Plus',
  'iPhone8,4': 'iPhone SE (GSM)',
  'iPhone9,1': 'iPhone 7',
  'iPhone9,2': 'iPhone 7 Plus',
  'iPhone9,3': 'iPhone 7',
  'iPhone9,4': 'iPhone 7 Plus',
  'iPhone10,1': 'iPhone 8',
  'iPhone10,2': 'iPhone 8 Plus',
  'iPhone10,3': 'iPhone X Global',
  'iPhone10,4': 'iPhone 8',
  'iPhone10,5': 'iPhone 8 Plus',
  'iPhone10,6': 'iPhone X GSM',
  'iPhone11,2': 'iPhone XS',
  'iPhone11,4': 'iPhone XS Max',
  'iPhone11,6': 'iPhone XS Max Global',
  'iPhone11,8': 'iPhone XR',
  'iPhone12,1': 'iPhone 11',
  'iPhone12,3': 'iPhone 11 Pro',
  'iPhone12,5': 'iPhone 11 Pro Max',
  'iPhone12,8': 'iPhone SE 2nd Gen',
};

export default appleDeviceTypes;
