import React from 'react';
import { useDateRange } from 'context/date-range';
import { LoadingIcon, ErrorHandler } from 'elements';
import { useOrganizationCsv, useOrganizationLocationData } from '../__hooks__';
import { Container } from './OrganizationExportButtons.styled';

const OrganizationExportButtons = () => {
  const [dateRange] = useDateRange();
  const { isLoading, isError, data, error } = useOrganizationLocationData({ dateRange });
  const { ExportLocationData, ExportActivityData } = useOrganizationCsv({
    locationData: data?.locationData,
    activityData: data?.activityData,
    organization: data?.organization,
  });

  if (isLoading) {
    return <LoadingIcon />;
  }

  if (isError) {
    return <ErrorHandler error={error} message="Failed to pull organization's location data" />;
  }

  return (
    <Container>
      {ExportLocationData}
      {ExportActivityData}
    </Container>
  );
};

export default OrganizationExportButtons;
