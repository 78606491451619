import React from 'react';
import { CreateRadarEventsInput } from 'API';
import moment from 'moment';
import { Csv, ExportButton, Container, EntriesLabel } from './useOrganizationCsv.styled';

type useOrganizationCsvProps = {
  locationData?: CreateRadarEventsInput[];
  activityData?: CreateRadarEventsInput[];
  organization?: string;
};

const ExportLocationData = ({
  locationData,
  organization,
}: {
  locationData: CreateRadarEventsInput[];
  organization: string;
}) => {
  return (
    <Container>
      <Csv
        filename={`${organization}-${moment().format()}-location.csv`}
        data={locationData.map((item) => ({
          user: item.user,
          timestamp: moment(item.timestamp).format('DD/MM/YYYY-HH:mmZ'),
          latitude: item.locationMeta?.latitude,
          longitude: item.locationMeta?.longitude,
          altitude: item.locationMeta?.altitude,
          accuracy: item.locationMeta?.accuracy,
        }))}
      >
        <ExportButton>Export Location Data</ExportButton>
      </Csv>
      <EntriesLabel>{`${locationData.length} entries`}</EntriesLabel>
    </Container>
  );
};

const ExportActivityData = ({
  activityData,
  organization,
}: {
  activityData: CreateRadarEventsInput[];
  organization: string;
}) => {
  return (
    <Container>
      <Csv
        filename={`${organization}-${moment().format()}-activity.csv`}
        data={activityData.map((item) => {
          const locations = () =>
            item.insights?.locations
              ?.map((location) => ({
                [`${location?.type}_latitude`]: location?.coordinates?.[0],
                [`${location?.type}_longitude`]: location?.coordinates?.[1],
              }))
              .reduce((prev: object, value: object) => {
                return {
                  ...prev,
                  ...(value && { ...value }),
                };
              }, {});
          return {
            user: item.user,
            timestamp: moment(item.timestamp).format('DD/MM/YYYY-HH:mmZ'),
            eventType: item.eventType,
            location: item?.place?.name,
            longitude: item.place?.location?.coordinates?.[0],
            latitude: item.place?.location?.coordinates?.[1],
            categories: item?.place?.categories,
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            // @ts-expect-error  confidence is returned from raw data; s3 converts raw string to object
            confidence: item?.confidence || item?.raw?.event?.confidence,
            ...locations(),
          };
        })}
      >
        <ExportButton>Export Activity Data</ExportButton>
      </Csv>
      <EntriesLabel>{`${activityData.length} entries`}</EntriesLabel>
    </Container>
  );
};

const useOrganizationCsv = ({
  locationData = [],
  activityData = [],
  organization = '',
}: useOrganizationCsvProps) => {
  return {
    ExportLocationData: (
      <ExportLocationData locationData={locationData} organization={organization} />
    ),
    ExportActivityData: (
      <ExportActivityData activityData={activityData} organization={organization} />
    ),
  };
};

export default useOrganizationCsv;
