import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Body, Header } from '../Typography/Typography';

const isActive = ({ currentRoute, to }: { currentRoute: string; to: string }) => {
  // highlight the daily questions link if user is on a user profile
  if (to.includes('/daily-questions') && currentRoute.includes('daily-questions')) return true;
  return currentRoute === to;
};

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
`;

export const PageHeader = styled(Header)`
  margin: 0;
`;

export const NavlinkContainer = styled.span<{
  currentRoute: string;
  to: string;
}>`
  margin-left: 2rem;

  p {
    transition: color 0.25s;
  }

  svg {
    transition: color 0.25s;
  }

  a:active,
  a:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }

  ${(props) => {
    return isActive(props)
      ? `
    p {
      color: #094074;
    }

    svg {
      color: #094074 !important;
    }
    `
      : '';
  }}

  &:hover {
    p {
      color: #094074;
    }

    svg {
      color: #094074 !important;
    }
  }
`;

export const Navlink = styled(RouterNavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0;
`;

export const NavlinkLabel = styled(Body)`
  color: #b1b1b1;
  font-weight: 600;
  margin: 0;
`;
