import React from 'react';
import { Card, Header, Body } from 'elements';

const Home = () => {
  return (
    <Card>
      <Header>Organizational Statistics</Header>
      <Body>We can put statistics related to your organization here.</Body>
    </Card>
  );
};

export default Home;
