import React from 'react';
import { CreateDailyQuestionInput } from 'API';
import moment from 'moment';
import {
  Container,
  Data,
  HeaderContainer,
  CardTitle,
  Timestamp,
  DataLabel,
  DataValue,
} from './DailyQuestionsResult.styled';

type DailyQuestionsResultProps = {
  result: CreateDailyQuestionInput;
};

const DailyQuestionsResult = ({ result }: DailyQuestionsResultProps) => {
  return (
    <Container>
      <HeaderContainer>
        <CardTitle>Daily Questions Result</CardTitle>
        <Timestamp secondary>{`${moment(result.timestamp).format(
          'LLLL',
        )} - ${moment.tz.guess()}`}</Timestamp>
      </HeaderContainer>
      <Data>
        <div>
          <DataLabel secondary>Bodily temperature (ºC)</DataLabel>
          <DataValue>{result.bodyTemperature || '-'}</DataValue>
        </div>
        <div>
          <DataLabel secondary>Sp02 (%)</DataLabel>
          <DataValue>{result.oxygen || '-'}</DataValue>
        </div>
        <div>
          <DataLabel secondary>Sleep quality</DataLabel>
          <DataValue>{result.sleepQuality || '-'}</DataValue>
        </div>
        <div>
          <DataLabel secondary>Level of recovery</DataLabel>
          <DataValue>{result.recovery || '-'}</DataValue>
        </div>
        <div>
          <DataLabel secondary>Anxiety</DataLabel>
          <DataValue>{result.anxiety || '-'}</DataValue>
        </div>
        <div>
          <DataLabel secondary>Loss of sense of taste/smell</DataLabel>
          <DataValue>{result.lossOfTasteSmell || '-'}</DataValue>
        </div>
        <div>
          <DataLabel secondary>Cough</DataLabel>
          <DataValue>{result.cough || '-'}</DataValue>
        </div>
        <div>
          <DataLabel secondary>Difficulties in breathing</DataLabel>
          <DataValue>{result.difficultyBreathing || '-'}</DataValue>
        </div>
        <div>
          <DataLabel secondary>Sore throat</DataLabel>
          <DataValue>{result.soreThroat || '-'}</DataValue>
        </div>
        <div>
          <DataLabel secondary>Aching limbs</DataLabel>
          <DataValue>{result.achingLimbs || '-'}</DataValue>
        </div>
      </Data>
    </Container>
  );
};

export default DailyQuestionsResult;
