import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { LoadingIcon, ErrorHandler, Button } from 'elements';
import { useOrganizationSettings } from 'hooks';
import { useOrganization } from 'context/organization';
import { useAlert } from 'context/alert';
import TextField from '@material-ui/core/TextField';
import {
  Container,
  Setting,
  Grid,
  ActionRow,
  StyledLabel,
  StyledSubLabel,
} from './Organization.styled';

type OrganizationSettings = {
  alert_time_hours: number;
  push_notify: number;
};

const Organization = () => {
  const [organization] = useOrganization();
  const {
    organizationSettings,
    setOrganizationSettings: [submit, organizationSettingsMutation],
  } = useOrganizationSettings();
  const { control, handleSubmit, reset, errors } = useForm<OrganizationSettings>();
  const { successAlert, errorAlert } = useAlert();

  useEffect(() => {
    reset();
  }, [organization, reset]);

  useEffect(() => {
    if (organizationSettingsMutation.isSuccess) {
      successAlert({ message: 'Successfully updated organizational settings' });
      organizationSettingsMutation.reset();
    }
  });

  if (organizationSettings.isLoading) {
    return <LoadingIcon />;
  }

  if (organizationSettings.isError || !organizationSettings?.data?.id) {
    return (
      <ErrorHandler
        error={organizationSettings.error}
        message="Failed to fetch organizational settings"
      />
    );
  }

  if (organizationSettingsMutation.isError) {
    errorAlert({ message: 'Failed to update organizational settings' });
  }

  return (
    <form
      onSubmit={handleSubmit(async (formData) => {
        await submit({
          alert_time_hours: formData.alert_time_hours,
          push_notify: formData.push_notify,
          id: organizationSettings.data.id,
        });
        organizationSettings.refetch({});
      })}
    >
      <Container>
        <Grid>
          <Setting>
            <div>
              <StyledLabel htmlFor="alert_time_hours">
                Number of hours before a &quot;no location data&quot; alert is generated
              </StyledLabel>
              <StyledSubLabel secondary>
                If a user does not track location data for x amount of hours, an email will be sent
                to the members of this organization.
              </StyledSubLabel>
            </div>
            <Controller
              as={TextField}
              required
              control={control}
              id="alert_time_hours"
              name="alert_time_hours"
              defaultValue={organizationSettings.data?.alert_time_hours || ''}
              type="text"
              margin="normal"
              fullWidth
              autoComplete="off"
              InputLabelProps={{ required: false }}
            />
          </Setting>

          <Setting>
            <div>
              <StyledLabel htmlFor="push_notify">
                Number of hours before a push notification is generated
              </StyledLabel>
              <StyledSubLabel secondary>
                If a user does not track location data for x amount of hours, a push notification
                will be sent to their phone.
              </StyledSubLabel>
            </div>
            <Controller
              as={TextField}
              required
              error={!!errors.push_notify}
              control={control}
              id="push_notify"
              name="push_notify"
              rules={{ pattern: /^^([2-9]\d|[1-9]\d{2,})$/ }}
              defaultValue={organizationSettings.data?.push_notify || ''}
              type="text"
              margin="normal"
              fullWidth
              autoComplete="off"
              InputLabelProps={{ required: false }}
            />
          </Setting>
        </Grid>
        <ActionRow>
          <Button loading={organizationSettingsMutation.isLoading} htmlType="submit">
            Save
          </Button>
        </ActionRow>
      </Container>
    </form>
  );
};

export default Organization;
