import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { Button, Body } from 'elements';

export const Csv = styled(CSVLink)`
  text-decoration: none;
`;

export const ExportButton = styled(Button)`
  margin: 0 0 8px 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EntriesLabel = styled(Body)`
  margin: 0;
`;
