import { useQuery, useMutation } from 'react-query';
import { useOrganization } from 'context/organization';
import { TrackOption } from 'API';
import fetchOrganizationSettings from './utils/fetchOrganizationSettings';
import submitOrganizationSettings from './utils/submitOrganizationSettings';

const useOrganizationSettings = () => {
  const [organization] = useOrganization();

  const query = useQuery<
    { alert_time_hours: number | null; push_notify: number | null; id: string },
    [string, string]
  >(['organization', organization], fetchOrganizationSettings);
  const mutation = useMutation<
    any,
    {
      id: string;
      alert_time_hours?: number | null;
      trackOption?: TrackOption | null;
      push_notify?: number | null;
    }
  >((args) => submitOrganizationSettings(args, organization));

  return {
    organizationSettings: query,
    setOrganizationSettings: mutation,
  };
};

export default useOrganizationSettings;
