import React from 'react';
import { appleDeviceTypes } from 'dictionaries';
import { CreateVerifiedNativeUsersInput } from 'API';
import { Item, Label, Value, DeviceInfo } from './DeviceInformation.styled';

type DeviceInformationProps = {
  user: CreateVerifiedNativeUsersInput | undefined;
};

const DeviceInformation = ({ user }: DeviceInformationProps) => {
  return (
    <DeviceInfo>
      <Item>
        <Label>App Version</Label>
        <Value>{user?.deviceMeta?.appVersion || 'N/A'}</Value>
      </Item>
      <Item>
        <Label>Device</Label>
        <Value>
          {(() => {
            if (!user?.deviceMeta) return 'N/A';

            // check for iOS device
            const iosDevice = appleDeviceTypes[user?.deviceMeta?.deviceId || 'default'];

            // check for Android device
            const androidDevice = user?.deviceMeta?.model;

            // return correct device
            return iosDevice || androidDevice || 'N/A';
          })()}
        </Value>
      </Item>
      <Item>
        <Label>OS</Label>
        <Value>
          {(() => {
            if (!user?.deviceMeta?.Platform) return 'N/A';

            // return formatted iOS
            if (user?.deviceMeta?.Platform === 'ios') return 'iOS';

            // return formatted Android
            if (user?.deviceMeta?.Platform === 'android') return 'Android';

            return 'N/A';
          })()}
        </Value>
      </Item>
      <Item>
        <Label>OS Version</Label>
        <Value>{user?.deviceMeta?.systemVersion || 'N/A'}</Value>
      </Item>
      <Item>
        <Label>Carrier</Label>
        <Value>
          {(() => {
            if (!user?.deviceMeta?.carrier) return 'N/A';
            if (user?.deviceMeta?.carrier === 'unknown') return 'N/A';
            return user?.deviceMeta?.carrier;
          })()}
        </Value>
      </Item>
      <Item>
        <Label>Location Permissions</Label>
        <Value>
          {(() => {
            if (!user?.deviceMeta?.locationProviders) return 'N/A';

            // check ios location permissions
            const iosPermissions = JSON.parse(
              user?.deviceMeta?.locationProviders || '{}',
            )?.locationServicesEnabled?.toString();

            // check android location permissions
            const androidPermissions = JSON.parse(
              user?.deviceMeta?.locationProviders || '{}',
            )?.gps?.toString();

            // return correct location permissions data
            return iosPermissions || androidPermissions || 'N/A';
          })()}
        </Value>
      </Item>
    </DeviceInfo>
  );
};

export default DeviceInformation;
