import React from 'react';
import { Header, DateRangePicker } from 'elements';
import { UserExportButtons } from 'components/user';
import { CsvHeader } from './Export.styled';

const Export = () => {
  return (
    <>
      <CsvHeader>
        <Header>Export</Header>
        <DateRangePicker />
      </CsvHeader>
      <UserExportButtons />
    </>
  );
};

export default Export;
