/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextInput } from './Input.styled';

const Input = React.forwardRef(
  (
    props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    return <TextInput role="textbox" {...props} ref={ref} />;
  },
);

export default Input;
