/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRadarEvents = /* GraphQL */ `
  mutation CreateRadarEvents($input: CreateRadarEventsInput!) {
    createRadarEvents(input: $input) {
      eventType
      organization
      timestamp
      user
      createdAt
      deviceId
      eventId
      eventLocation {
        coordinates
        type
      }
      locationMeta {
        accuracy
        altitude
        course
        courseAccuracy
        latitude
        longitude
        speed
        speedAccuracy
        verticalAccuracy
      }
      radarUserId
      raw
      ttl
      updatedAt
      userLocation {
        coordinates
        type
      }
      place {
        name
        categories
        location {
          coordinates
          type
        }
        chain {
          name
          slug
          externalId
          metadata
        }
      }
      insights {
        state {
          home
          office
          traveling
          commuting
        }
        locations {
          type
          location {
            coordinates
            type
          }
          coordinates
          confidence
          updatedAt
        }
      }
      confidence
    }
  }
`;
export const updateRadarEvents = /* GraphQL */ `
  mutation UpdateRadarEvents($input: UpdateRadarEventsInput!) {
    updateRadarEvents(input: $input) {
      eventType
      organization
      timestamp
      user
      createdAt
      deviceId
      eventId
      eventLocation {
        coordinates
        type
      }
      locationMeta {
        accuracy
        altitude
        course
        courseAccuracy
        latitude
        longitude
        speed
        speedAccuracy
        verticalAccuracy
      }
      radarUserId
      raw
      ttl
      updatedAt
      userLocation {
        coordinates
        type
      }
      place {
        name
        categories
        location {
          coordinates
          type
        }
        chain {
          name
          slug
          externalId
          metadata
        }
      }
      insights {
        state {
          home
          office
          traveling
          commuting
        }
        locations {
          type
          location {
            coordinates
            type
          }
          coordinates
          confidence
          updatedAt
        }
      }
      confidence
    }
  }
`;
export const createNativeUsers = /* GraphQL */ `
  mutation CreateNativeUsers($input: CreateNativeUsersInput!) {
    createNativeUsers(input: $input) {
      deviceId
      user
      userId
      organization
      radarUserId
    }
  }
`;
export const updateNativeUsers = /* GraphQL */ `
  mutation UpdateNativeUsers($input: UpdateNativeUsersInput!) {
    updateNativeUsers(input: $input) {
      deviceId
      user
      userId
      organization
      radarUserId
    }
  }
`;
export const createDailyQuestion = /* GraphQL */ `
  mutation CreateDailyQuestion($input: CreateDailyQuestionInput!) {
    createDailyQuestion(input: $input) {
      user
      timestamp
      anxiety
      bodyTemperature
      cough
      difficultyBreathing
      lossOfTasteSmell
      soreThroat
      achingLimbs
      recovery
      sleepQuality
      organization
      oxygen
    }
  }
`;
export const updateDailyQuestion = /* GraphQL */ `
  mutation UpdateDailyQuestion($input: UpdateDailyQuestionInput!) {
    updateDailyQuestion(input: $input) {
      user
      timestamp
      anxiety
      bodyTemperature
      cough
      difficultyBreathing
      lossOfTasteSmell
      soreThroat
      achingLimbs
      recovery
      sleepQuality
      organization
      oxygen
    }
  }
`;
export const createVerifiedNativeUsers = /* GraphQL */ `
  mutation CreateVerifiedNativeUsers($input: CreateVerifiedNativeUsersInput!) {
    createVerifiedNativeUsers(input: $input) {
      user
      organization
      activatedBy
      active
      activeDate
      userId
      cancelDate
      deviceMeta {
        deviceId
        systemVersion
        carrier
        locationProviders
        Platform
        deviceToken
        appVersion
        model
      }
      pushNotifications
    }
  }
`;
export const updateVerifiedNativeUsers = /* GraphQL */ `
  mutation UpdateVerifiedNativeUsers($input: UpdateVerifiedNativeUsersInput!) {
    updateVerifiedNativeUsers(input: $input) {
      user
      organization
      activatedBy
      active
      activeDate
      userId
      cancelDate
      deviceMeta {
        deviceId
        systemVersion
        carrier
        locationProviders
        Platform
        deviceToken
        appVersion
        model
      }
      pushNotifications
    }
  }
`;
export const deleteVerifiedNativeUsers = /* GraphQL */ `
  mutation DeleteVerifiedNativeUsers($input: DeleteVerifiedNativeUsersInput!) {
    deleteVerifiedNativeUsers(input: $input) {
      user
      organization
      activatedBy
      active
      activeDate
      userId
      cancelDate
      deviceMeta {
        deviceId
        systemVersion
        carrier
        locationProviders
        Platform
        deviceToken
        appVersion
        model
      }
      pushNotifications
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      organization
      id
      alert_time_hours
      push_notify
      alertEmails
      trackOption
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization
      id
      alert_time_hours
      push_notify
      alertEmails
      trackOption
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      organization
      id
      alert_time_hours
      push_notify
      alertEmails
      trackOption
    }
  }
`;
export const createDevices = /* GraphQL */ `
  mutation CreateDevices($input: CreateDevicesInput!) {
    createDevices(input: $input) {
      model
      brand
      name
      device
    }
  }
`;
export const updateDevices = /* GraphQL */ `
  mutation UpdateDevices($input: UpdateDevicesInput!) {
    updateDevices(input: $input) {
      model
      brand
      name
      device
    }
  }
`;
export const deleteDevices = /* GraphQL */ `
  mutation DeleteDevices($input: DeleteDevicesInput!) {
    deleteDevices(input: $input) {
      model
      brand
      name
      device
    }
  }
`;
