import styled from 'styled-components';

export const FixedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

export const Container = styled.div`
  div {
    cursor: default !important;
  }
`;
