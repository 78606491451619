import { API, graphqlOperation } from 'aws-amplify';
import { createDailyQuestion } from 'graphql/mutations';
import { CreateDailyQuestionMutation, CreateVerifiedNativeUsersInput } from 'API';
import { DailyQuestionsForm } from 'types';

type submitDailyQuestions = (props: {
  formData: DailyQuestionsForm;
  currentUser: CreateVerifiedNativeUsersInput | undefined;
}) => Promise<CreateDailyQuestionMutation>;

const submitDailyQuestions: submitDailyQuestions = async ({ formData, currentUser }) => {
  // fetch users by group
  const { data } = (await API.graphql(
    graphqlOperation(createDailyQuestion, {
      input: {
        user: currentUser?.user,
        organization: currentUser?.organization,
        ...formData,
      },
    }),
  )) as {
    data: CreateDailyQuestionMutation;
  };

  return data;
};

export default submitDailyQuestions;
