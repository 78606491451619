import React from 'react';
import { withAuthenticator, AmplifyForgotPassword, AmplifySignIn } from '@aws-amplify/ui-react';
import { useAlert } from 'context/alert';
import Sidebar from '../Sidebar/Sidebar';
import DataDisplay from '../DataDisplay/DataDisplay';
import Header from '../Header/Header';
import { Container, Content } from './App.styled';

const App = () => {
  const { Alert } = useAlert();

  return (
    <Container>
      {Alert}
      <Sidebar />
      <Content>
        <Header />
        <DataDisplay />
      </Content>
    </Container>
  );
};

export default withAuthenticator(App, {
  children: [
    <AmplifySignIn key="signin" usernameAlias="email" slot="sign-in">
      <div slot="secondary-footer-content" />
    </AmplifySignIn>,
    <AmplifyForgotPassword key="forgotpassword" usernameAlias="email" slot="forgot-password" />,
  ],
});
