import React from 'react';
import { ListItem } from '@material-ui/core';
import { useCognitoUser } from 'hooks';
import _ from 'lodash';
import { useOrganization } from 'context/organization';
import {
  Container,
  OrganizationName,
  Title,
  MaterialDialog,
} from './SwitchOrganizationDialog.styled';

type SwitchOrganizationDialogProps = {
  open: boolean;
  setOpen: Function;
};

const SwitchOrganizationDialog = ({ open, setOpen }: SwitchOrganizationDialogProps) => {
  const { data } = useCognitoUser();
  const [, setOrganization] = useOrganization();

  return (
    <MaterialDialog open={open} onClose={() => setOpen(false)}>
      <Container>
        <Title>Switch Organization</Title>

        {/* sort alphabetically, not case sensitive */}
        {_.orderBy(
          data?.groups.filter((group) => group !== 'Admin'),
          [(item) => item.toUpperCase()],
        ).map((group) => (
          <ListItem
            button
            key={group}
            data-testid="organization-option"
            onClick={() => {
              setOrganization(group);
              setOpen(false);
            }}
          >
            <OrganizationName>{_.startCase(group)}</OrganizationName>
          </ListItem>
        ))}
      </Container>
    </MaterialDialog>
  );
};

export default SwitchOrganizationDialog;
