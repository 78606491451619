import React from 'react';
import { useLocation } from 'react-router-dom';
import Card from '../Card/Card';
import {
  Container,
  PageHeader,
  Navigation,
  NavlinkContainer,
  Navlink,
  NavlinkLabel,
} from './SectionNavigationBar.styled';

const NavButton = ({ children, to }: { children: string; to: string }) => {
  const { pathname: currentRoute } = useLocation();

  return (
    <NavlinkContainer to={to} currentRoute={currentRoute}>
      <Navlink to={to}>
        <NavlinkLabel>{children}</NavlinkLabel>
      </Navlink>
    </NavlinkContainer>
  );
};

type SectionNavigationBarProps = {
  title: string;
  routes: { route: string; displayName: string }[];
};

const SectionNavigationBar = ({ title, routes }: SectionNavigationBarProps) => {
  return (
    <Card>
      <Container>
        <PageHeader>{title}</PageHeader>
        <Navigation>
          {routes.map(({ route, displayName }) => {
            return (
              <NavButton key={route + displayName} to={route}>
                {displayName}
              </NavButton>
            );
          })}
        </Navigation>
      </Container>
    </Card>
  );
};

export default SectionNavigationBar;
