import styled from 'styled-components';
import { BsHouseDoorFill } from 'react-icons/bs';
import { TiLocation } from 'react-icons/ti';
import { MdWork } from 'react-icons/md';

type PinContainerProps = {
  size: number;
  type: 'home' | 'place' | 'work';
};

export const PinContainer = styled.div<PinContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => {
    if (props.type === 'home') {
      return props.theme.primary2;
    }

    if (props.type === 'place') {
      return props.theme.purple3;
    }

    if (props.type === 'work') {
      return props.theme.danger1;
    }

    return 'black';
  }};
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  border-radius: 50%;
  padding: 6px;
  transform: ${(props) => `translate(${-props.size / 2}px, ${-props.size}px)`};
`;

export const HomeIcon = styled(BsHouseDoorFill)`
  cursor: pointer;
  fill: white;
  stroke: white;
  font-size: 20px;
  color: white;
`;

export const PlaceIcon = styled(TiLocation)`
  cursor: pointer;
  fill: white;
  stroke: white;
  font-size: 20px;
  color: white;
`;

export const WorkIcon = styled(MdWork)`
  cursor: pointer;
  fill: white;
  stroke: white;
  font-size: 20px;
  color: white;
`;
