import { CreateVerifiedNativeUsersInput } from 'API';

const filterActiveUsers = (data: CreateVerifiedNativeUsersInput[] | undefined) => {
  if (!data) {
    return undefined;
  }
  return data.filter(({ active }) => active === 'yes');
};

export default filterActiveUsers;
