import { createMuiTheme } from '@material-ui/core';
import customTheme from './theme';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: customTheme.primary2,
    },
    secondary: {
      main: customTheme.steel2,
    },
    success: {
      main: customTheme.green2,
    },
    error: {
      main: customTheme.danger2,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'sans-serif',
    ].join(','),
  },
});

export default theme;
