import React from 'react';
import Card from '../Card/Card';
import { Input } from './Searchbox.styled';

const Searchbox = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <Card noPadding>
      <Input placeholder="Search users" value={value} onChange={onChange} />
    </Card>
  );
};

export default Searchbox;
