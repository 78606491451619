import styled from 'styled-components';
import { Body } from 'elements';
import { NavLink as RouterNavLink } from 'react-router-dom';

const isActive = ({ currentRoute, to }: { currentRoute: string; to: string }) => {
  // highlight the users link if on a user profile
  if (to === '/users' && currentRoute.includes('user') && !currentRoute.includes('users'))
    return true;
  if (to === '/settings' && currentRoute.includes('settings')) return true;
  return currentRoute === to;
};

export const NavlinkContainer = styled.span<{
  currentRoute: string;
  to: string;
}>`
  p {
    transition: color 0.25s;
  }

  svg {
    transition: color 0.25s;
  }

  a:active,
  a:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }

  ${(props) => {
    return isActive(props)
      ? `
    p {
      color: #094074;
    }

    svg {
      color: #094074 !important;
    }
    `
      : '';
  }}

  &:hover {
    p {
      color: #094074;
    }

    svg {
      color: #094074 !important;
    }
  }
`;

export const Navlink = styled(RouterNavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 1rem 0;
`;

export const NavlinkLabel = styled(Body)`
  color: #b1b1b1;
  font-weight: 600;
  margin: 0 0 0 1rem;
`;

export const Container = styled.div`
  background-color: white;
  width: 364px;
  box-shadow: ${(props) => props.theme.centeredBoxShadow};
`;

export const Content = styled.div`
  position: sticky;
  padding: 32px;
  top: 0;
  left: 0;
`;
