import styled from 'styled-components';

type StyledButtonProps = {
  myType: 'filled' | 'text';
  loading: 'true' | 'false';
};

export const StyledButton = styled.button<StyledButtonProps>`
  all: unset;
  font-family: ${(props) => props.theme.bodyFont};
  text-align: center;
  background-color: ${(props) => {
    if (props.myType === 'filled') {
      return props.theme.primary2;
    }
    if (props.myType === 'text') {
      return 'rgba(0, 0, 0, 0)';
    }
    return props.theme.primary2;
  }};
  padding: ${(props) => {
    if (props.myType === 'filled') {
      return '8px 16px';
    }
    if (props.myType === 'text') {
      return '8px 16px';
    }
    return '16px';
  }};
  color: ${(props) => {
    if (props.myType === 'filled') {
      return 'white';
    }
    if (props.myType === 'text') {
      return props.theme.primary2;
    }
    return '16px';
  }};
  opacity: ${(props) => (props.disabled || props.loading === 'true' ? '0.4' : '1')};
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: ${(props) => {
    if (props.loading === 'true') {
      return 'wait';
    }

    if (props.disabled) {
      return 'not-allowed';
    }

    return 'pointer';
  }};
  align-items: center;
  min-width: 32px;
  margin: 0;
  transition: background-color 0.2s, color 0.2s;

  box-shadow: ${(props) => {
    if (props.myType === 'filled') {
      return props.theme.cardBoxShadow;
    }
    if (props.myType === 'text') {
      return 'none';
    }
    return '16px';
  }};

  &:hover:enabled {
    background-color: ${(props) => {
      if (props.myType === 'filled') {
        return props.theme.primary1;
      }
      if (props.myType === 'text') {
        return 'rgba(0, 152, 198, 0.1)';
      }
      return '16px';
    }};

    color: ${(props) => {
      if (props.myType === 'filled') {
        return 'white';
      }
      if (props.myType === 'text') {
        return props.theme.primary2;
      }
      return '16px';
    }};
  }
`;
