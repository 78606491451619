import React from 'react';
import { ErrorHandler, SubHeader, LoadingIcon } from 'elements';
import { Center } from 'styles/utils';
import { useUsersList } from 'context/users';
import { useAlert } from 'context/alert';
import { MutationResult } from 'react-query';
import { DeleteVerifiedNativeUsersMutation } from 'API';
import { VerifiedUserListContainer, UsersListGrid } from './Users.styled';
import UserCard from '../../../components/settings/UserCard/UserCard';
import AddUser from '../../../components/settings/AddUser/AddUser';

const UserList = () => {
  const { isLoading, isError, data: userList, error, refetch } = useUsersList();
  const { successAlert, errorAlert } = useAlert();

  if (isLoading) {
    return <LoadingIcon />;
  }

  if (isError || userList === undefined || userList === null) {
    return <ErrorHandler error={error} message="Failed to fetch users list" />;
  }

  return (
    <VerifiedUserListContainer>
      <AddUser />
      <UsersListGrid>
        {userList.length !== 0 ? (
          userList.map((user) => (
            <UserCard
              key={`${user.user}${user.activeDate}`}
              user={user}
              handleDeleteUser={
                // used to handle user deletion
                async (
                  deleteUserMutation: MutationResult<DeleteVerifiedNativeUsersMutation, Error>,
                ) => {
                  await refetch();

                  if (deleteUserMutation.isSuccess) {
                    successAlert({ message: 'Successfully deleted user' });
                    deleteUserMutation.reset();
                  }

                  if (deleteUserMutation.isError) {
                    errorAlert({ message: 'Failed to delete user' });
                    deleteUserMutation.reset();
                  }
                }
              }
            />
          ))
        ) : (
          <Center>
            <SubHeader>No users exists in this group.</SubHeader>
          </Center>
        )}
      </UsersListGrid>
    </VerifiedUserListContainer>
  );
};

export default UserList;
