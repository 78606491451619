import React from 'react';
import moment from 'moment';
import { useDateRange } from 'context/date-range';
import { DateHeader, StartDatePicker, EndDatePicker } from './DateRangePicker.styled';

const DateRangePicker = () => {
  const [dateRange, setDateRange] = useDateRange();

  return (
    <DateHeader>
      <StartDatePicker
        id="start-date"
        value={dateRange.start}
        format="DD/MM/yyyy"
        label="Start Date"
        minDate={moment().subtract(30, 'days')}
        maxDate={moment()}
        onChange={(start) => {
          const newDate = moment(start);

          if (newDate.isValid()) {
            setDateRange({
              ...dateRange,
              start,
            });
          }
        }}
      />
      <EndDatePicker
        id="end-date"
        value={dateRange.end}
        format="DD/MM/yyyy"
        label="End Date"
        minDate={dateRange.start || moment().subtract(30, 'days')}
        maxDate={moment()}
        onChange={(end) => {
          const newDate = moment(end);

          if (newDate.isValid()) {
            setDateRange({
              ...dateRange,
              end,
            });
          }
        }}
      />
    </DateHeader>
  );
};

export default DateRangePicker;
